import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutomationHistoryService {

  baseUrl = environment.apiEndpoint + '/AutomationHistory';

  constructor(private http: HttpClient) {}

  GetHistoryByProject(projectId: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl  + '/' + projectId + '/history');
  }

}
