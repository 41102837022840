import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { QuotaQuestion } from 'core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuotaService {

baseUrl = environment.apiEndpoint + '/Quota/';

constructor(private http: HttpClient) {}

  ListQuestions(instance: string = '00000000-0000-0000-0000-000000000000'): Observable<QuotaQuestion[]> {
    return this.http.get<QuotaQuestion[]>(this.baseUrl  + 'questions/' + instance);
  }

  GetQuestion(id: string): Observable<QuotaQuestion> {
    return this.http.get<QuotaQuestion>(this.baseUrl + 'question/' + id);
  }

  AddQuestion(model: any): Observable<QuotaQuestion> {
    return this.http.post<QuotaQuestion>(this.baseUrl + 'question', model);
  }

  EditQuestion(model: any): Observable<QuotaQuestion> {
    return this.http.put<QuotaQuestion>(this.baseUrl + 'question', model);
  }

  DeleteQuestion(model: any) {
    return this.http.delete(this.baseUrl + 'question/' + model.id);
  }
  
  GetQuota(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + id + '/quota');
  }
  SaveQuota(id, quotaDetails) {
    return this.http.post<any>(this.baseUrl + id + '/quota', quotaDetails);
  }

  DeleteQuotaGroup(id, groupId) {
    return this.http.delete<any>(this.baseUrl + id + '/quotaGroup/' + groupId);
  }

  CloneQuotaFromProject(projectId, templateProjectId) {
    return this.http.put<any>(this.baseUrl + projectId + '/quota/clone/' + templateProjectId, {});
  }

  SaveSingleQuota(projectId, quota, refreshParticipants) {
    return this.http.post<any>(this.baseUrl + projectId + '/quota/' + quota.groupId + '/inline/' + refreshParticipants, quota);
  }
  SaveSingleVendorQuota(projectId, quotaDetailId, vendorQuota) {
    return this.http.post<any>(this.baseUrl + projectId + '/quota/' + quotaDetailId + '/vendor/inline/', vendorQuota);
  }

  ImportQuotaFromFile(id, fileName, allocationType) {
    return this.http.post<any>(this.baseUrl + id + '/quota/' + fileName + '/' + allocationType, {});
  }

  NestQuota(id, groupIds) {
    return this.http.post<any>(this.baseUrl + id + '/quota/nest', groupIds);
  }

  UndoNest(id, groupId) {
    return this.http.delete<any>(this.baseUrl + id + '/quota/nest/' + groupId);
  }

  CloneQuotaFromORInstance(instanceId) {
    return this.http.post<any>(this.baseUrl + instanceId + '/clone', {});
  }
}
