import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { JumpDataFileReportList, JumpDataFileInfo, JumpDataFileGenerationRequestBody, JumpDataFileType, JumpDataFileDownloadFileType, JumpDataFileGenerationResponse } from 'core';

@Injectable({
  providedIn: 'root'
})
export class JumpDataFilesService {
  baseUrl = environment.apiEndpoint + '/JUMP/data-files/';


  constructor(private http: HttpClient) { }

  listDataFiles(jst: string, jpt:string, reportIds: string[] = []): Observable<JumpDataFileReportList> {
    return this.http.get<JumpDataFileReportList>(this.baseUrl + 'list' + (reportIds.length > 0 ? `/${reportIds.join(",")}` : ""), {
      headers: new HttpHeaders({
        jst: jst,
        jpt: jpt
      })
    });
  }

  getDataFileInfo(jst: string, jpt: string): Observable<JumpDataFileInfo> {
    return this.http.get<JumpDataFileInfo>(this.baseUrl + 'info', {
      headers: new HttpHeaders({
        jst: jst,
        jpt: jpt
      })
    });
  }


  generateDataFile(jst: string, jpt: string, body: JumpDataFileGenerationRequestBody): Observable<JumpDataFileGenerationResponse> {
    return this.http.post<any>(this.baseUrl + 'generate', body, {
      headers: new HttpHeaders({
        jst: jst,
        jpt: jpt
      })
    });
  }

  downloadDataFile(jst: string, jpt: string, type: JumpDataFileDownloadFileType, reportId: string): Observable<any> {
    return this.http.get(this.baseUrl + 'download/' + type + '/' + reportId, {
      headers: new HttpHeaders({
        jst: jst,
        jpt: jpt
      }),
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

}
