import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { IconPickerModule } from 'ngx-icon-picker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxCurrencyDirective } from "ngx-currency";
import { DndModule } from 'ngx-drag-drop';
import { NgxColorsModule } from 'ngx-colors';
import {AutosizeModule} from 'ngx-autosize';

import { HelpersModule } from '../helpers/helpers.module';
import { RouterModule } from '@angular/router';

import { EmailAddressComponent } from './email-address/email-address.component';
import { AddressComponent } from './address/address.component';
import { WebsiteComponent } from './website/website.component';
import { SocialLinksComponent } from './social-links/social-links.component';
import { TitleComponent } from './title/title.component';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { ProjectStageComponent } from './project-stage/project-stage.component';
import { ProjectStagesComponent } from './project-stages/project-stages.component';
import { BrandCardComponent } from './brand-card/brand-card.component';
import { PhoneComponent } from './phone/phone.component';
import { ListComponent } from './list/list.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { FilterComponent } from './filter/filter.component';
import { SortByComponent } from './sortby/sortby.component';
import { ViewToggleComponent } from './view-toggle/view-toggle.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { ActionButtonComponent } from './action-button/action-button.component';
import { UploadComponent} from './upload/upload.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { TypeAheadComponent } from './type-ahead/type-ahead.component';
import { InputMultipleComponent } from './input-multiple/input-multiple.component';
import { LookupTableComponent } from './lookup-table/lookup-table.component';
import { DropdownStatusComponent } from './dropdown-status/dropdown-status.component';
import { DedupeStatusSetupComponent } from './dedupe-status-setup/dedupe-status-setup.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { DataGridComponent} from './data-grid/data-grid.component';
import { CleanidSummaryChartComponent } from './charts/cleanid-summary-chart/cleanid-summary-chart.component';
import { CleanidTimelineChartComponent } from './charts/cleanid-timeline-chart/cleanid-timeline-chart.component';
import { TrendChartComponent } from './charts/trend-chart/trend-chart.component';
import { ProgressGuageComponent } from './progress-guage/progress-guage.component';
import { CalendarComponent } from './calendar/calendar.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { GroupNotesWysiwygComponent } from './groupnotes-wysiwyg/groupnotes-wysiwyg.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { LogChartComponent } from './charts/log-chart/log-chart.component';
import { CleanidReportComponent } from './reports/cleanid-report/cleanid-report.component';
import { BidValueSelectComponent } from './bid-value-select/bid-value-select.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { ZohoDeskComponent } from './zoho-desk/zoho-desk.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { RuleManagerComponent } from './rule-manager/rule-manager.component';
import { AlertsManagerComponent } from './alerts-manager/alerts-manager.component';
import { SystemAlertsManagerComponent } from './system-alerts-manager/system-alerts-manager.component';
import { ListBoxComponent } from './list-box/list-box.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { PhoneWithCodeComponent } from './phone-with-code/phone-with-code.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { VendorEditComponent } from './vendor-edit/vendor-edit.component';
import { VendorEditCpiComponent } from './vendor-edit-cpi/vendor-edit-cpi.component';
import { VendorCpiGridComponent } from './vendor-cpi-grid/vendor-cpi-grid.component';
import { DropdownStandardComponent } from './dropdown-standard/dropdown-standard.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    EmailAddressComponent,
    AddressComponent,
    SocialLinksComponent,
    TitleComponent,
    ProfileImageComponent,
    BrandCardComponent,
    WebsiteComponent,
    PhoneComponent,
    PagetitleComponent,
    ListComponent,
    ListBoxComponent,
    ListFilterComponent,
    FilterComponent,
    SortByComponent,
    ViewToggleComponent,
    ContactCardComponent,
    ActionButtonComponent,
    UploadComponent,
    ToggleButtonComponent,
    TypeAheadComponent,
    InputMultipleComponent,
    LookupTableComponent,
    DropdownStatusComponent,
    DataGridComponent,
    DedupeStatusSetupComponent,
    DropdownComponent,
    DropdownStandardComponent,
    DropdownListComponent,
    CleanidSummaryChartComponent,
    CleanidTimelineChartComponent,
    ProgressGuageComponent,
    TrendChartComponent,
    CalendarComponent,
    WysiwygComponent,
    GroupNotesWysiwygComponent,
    ErrorDisplayComponent,
    SpinnerButtonComponent,
    LogChartComponent,
    CleanidReportComponent,
    BidValueSelectComponent,
    GlobalSearchComponent,
    ZohoDeskComponent,
    NotificationsComponent,
    ChatbotComponent,
    RuleManagerComponent,
    AlertsManagerComponent,
    SystemAlertsManagerComponent,
    PhoneWithCodeComponent,
    ProjectStageComponent,
    ProjectStagesComponent,
    VendorEditComponent,
    VendorEditCpiComponent,
    VendorCpiGridComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HelpersModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CKEditorModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    IconPickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxCurrencyDirective,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    GoogleChartsModule,
    FullCalendarModule,
    ReactiveFormsModule,
    DndModule,
    NgxColorsModule,
    AutosizeModule
  ],
  exports: [
    EmailAddressComponent,
    ReactiveFormsModule,
    AddressComponent,
    SocialLinksComponent,
    TitleComponent,
    ProfileImageComponent,
    BrandCardComponent,
    WebsiteComponent,
    PhoneComponent,
    PagetitleComponent,
    ListComponent,
    FilterComponent,
    SortByComponent,
    ListBoxComponent,
    ViewToggleComponent,
    ContactCardComponent,
    ActionButtonComponent,
    UploadComponent,
    ToggleButtonComponent,
    TypeAheadComponent,
    InputMultipleComponent,
    LookupTableComponent,
    DropdownStatusComponent,
    DedupeStatusSetupComponent,
    DropdownComponent,
    DropdownStandardComponent,
    DropdownListComponent,
    DataGridComponent,
    CleanidSummaryChartComponent,
    CleanidTimelineChartComponent,
    TrendChartComponent,
    CalendarComponent,
    WysiwygComponent,
    GroupNotesWysiwygComponent,
    ErrorDisplayComponent,
    ProgressGuageComponent,
    BsDatepickerModule,
    TimepickerModule,
    BsDropdownModule,
    ModalModule,
    TabsModule,
    AccordionModule,
    TypeaheadModule,
    PopoverModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxCurrencyDirective,
    IconPickerModule,
    CollapseModule,
    TooltipModule,
    AlertModule,
    HelpersModule,
    SpinnerButtonComponent,
    LogChartComponent,
    CleanidReportComponent,
    BidValueSelectComponent,
    GlobalSearchComponent,
    ZohoDeskComponent,
    NotificationsComponent,
    ChatbotComponent,
    RuleManagerComponent,
    AlertsManagerComponent,
    SystemAlertsManagerComponent,
    PaginationModule,
    GoogleChartsModule,
    FullCalendarModule,
    DndModule,
    NgxColorsModule,
    AutosizeModule,
    TitleComponent,
    PhoneWithCodeComponent,
    ProjectStageComponent,
    ProjectStagesComponent,
    VendorEditComponent,
    VendorEditCpiComponent,
    VendorCpiGridComponent
  ],
  providers: [provideNgxMask()]
})
export class ComponentsModule { }
