<div class="card">
  <div class="card-header or-primary-bg text-white ">
    <div class="h4 inline float-left"><em class="fa fa-users fa-sm"></em> Manage all Users</div>
    <div class="float-right">
      <div class="form-inline form">
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm inline" placeholder="Filter...">
        <button type="button" [routerLink]="['/user/add']" class="btn btn-sm btn-outline-light inline ml-2" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add User</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <tabset #staticTabs>
        <tab heading="Internal ({{usersInternals?.length || 0}})" (selectTab)="onSelect($event,'internal')">
          <div class="my-2">
            <or-list [items]="usersInternals | filter : filterText : filterColumns" 
            [layout]="layout" 
            [actions]="actions" 
            [listsettings]="settings"
            (settingsupdate)="saveUserListSetting($event)"
            (action)="handleAction($event,'internal')"></or-list>
          </div>
        </tab>
        <tab heading="Vendor ({{usersVendors?.length || 0}})" (selectTab)="onSelect($event,'vendor')">
          <div class="my-2">
            <or-list [items]="usersVendors | filter : filterText : filterColumns" 
              [layout]="layout" 
              [actions]="actions"
              [listsettings]="settings"
              (settingsupdate)="saveUserListSetting($event)"
             (action)="handleAction($event,'vendor')"></or-list>
          </div> 
     </tab>
      <tab heading="Client ({{usersClients?.length || 0}})" (selectTab)="onSelect($event,'client')">
        <div class="my-2">
          <or-list [items]="usersClients | filter : filterText : filterColumns" 
          [layout]="layout" 
          [actions]="actions"
          [listsettings]="settings"
          (settingsupdate)="saveUserListSetting($event)" 
          (action)="handleAction($event,'client')"></or-list>
        </div>
      </tab>
    </tabset>
  </div>
<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete {{this.deleteUser.firstName + ' ' + this.deleteUser.lastName}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>

<ng-template #modalResetOrInvite>
  <div class="modal-header">
    <p class="h5">Reset Password/Invite Account</p>
  </div>
  <div class="modal-body text-center">
    @if (this.sendUser.blockLogon) {
      <p class="h6">This user does not currently have access.</p>
      <p>Would you like to send an invite email that will enable this account and request them to set up their password.</p> 
      <button type="button" class="btn btn-sm or-secondary-bg text-white mr-2" (click)="openModalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-sm or-primary-bg text-white mr-2" (click)="inviteUser()">Yes, Send Invite</button>
    }
    @else {
      <p class="h6">Please select if you want to send a password reset or an invite email to this user.</p>
      <button type="button" class="btn btn-sm or-secondary-bg text-white mr-2" (click)="openModalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-sm or-primary-bg text-white mr-2" (click)="sendPasswordReset()">Send Password Reset </button>
      <button type="button" class="btn btn-sm or-primary-bg text-white mr-2" (click)="inviteUser()">Send Invite</button>
    }
  </div>
</ng-template>
