import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { JumpAuthRequestBody, JumpAuthResponse } from 'core';

@Injectable({
  providedIn: 'root'
})
export class JumpAuthService {
  baseUrl = environment.apiEndpoint + '/JUMP/';


  constructor(private http: HttpClient) { }

  getJumpToken(body: JumpAuthRequestBody): Observable<JumpAuthResponse> {
    return this.http.post<JumpAuthResponse>(this.baseUrl + "auth", body);
  }

}
