import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {AuthService, AlertifyService} from 'core';

@Component({
  selector: 'or-system-alerts-manager',
  templateUrl: './system-alerts-manager.component.html',
  styleUrls: ['./system-alerts-manager.component.scss']
})
export class SystemAlertsManagerComponent implements OnInit {

  @Input() public set rule(data) {
    this._rule = data;

    if (this._rule.ruleType == 'tasks') {
      this.scopeTypes = [{label: 'My projects', type: 'MyProjects'}, {label: 'All projects', type: 'AllProjects'}];
      this.metricTypes = [{label: 'Overdue', type:'OverdueTasksAssignedToMe'}, {label:'Assigned to me', type:'TasksAssignedToMe'}];
      this.alertMethods = ['Email', 'Slack'];
      this.selectedMetric = 'Select';
    }

    this.loadData();
  }

  @Input() alertHistory: Boolean = false; //used when displaying alert history modal
  @Input() surveySettings: any;

  @Output() conditionChanged = new EventEmitter();
  @ViewChild('ddScope', {static: false}) ddScope;

  _rule;
  scopeTypes = [{label: 'My projects', type: 'MyProjects'}, {label: 'My team\'s projects', type: 'MyTeamsProjects'}]

  metricTypes = [
    {label: 'LOI', type: 'LOI'},
    {label: 'IR', type: 'IR'},
    {label: 'Manual QCs', type: 'ManualQCs'},
    {label: 'DAR', type: 'DAR'},
    {label: 'DOR', type: 'DOR'},
    {label: 'CID Dupes', type: 'CIDDupes'},
    {label: 'CID Fraud', type: 'CIDFraud'}
  ];

  alertMethods = ['Email', 'SMS text message', 'Slack'];
  operators = [{label: 'Above', value: '>'}, {label: 'Below', value: '<'}];

  selectedScope = 'Select group';
  selectedMetric = 'Select metric';
  selectedMetricNumber: any = {label: '0', value: 0, suffix: ''};
  selectedOperator = 'Select';
  selectedAlertMethod = 'Select method';

  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };
  emailValidator = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private auth: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit(): void { }

  loadData() {

    if (this._rule.id || this._rule.copy) {
      this.selectedMetricNumber.suffix = '%';
      this.selectedScope = this.scopeTypes.find(x => x.type === this._rule.scopeType)?.label || 'Select group';
      this.selectedMetric = this.metricTypes.find(x => x.type === this._rule.rules[0].metricType)?.label || 'Select metric';
      this.selectedMetricNumber = {label: this._rule.rules[0].value, value: this._rule.rules[0].value, suffix: ''};
      this.selectedAlertMethod = this._rule.ruleActions[0].alertMethod;
      this.selectedOperator = this.operators.find(x => x.value ===  this._rule.rules[0].operator)?.label || 'Select';
    }
  }

  applyScope(newValue) {
    this.selectedScope = newValue.label;
    this.ddScope.hide();
    this.conditionChanged.emit({condition: 'scope', newValue});
  }

  applyMetric(newValue) {
    this.selectedMetric = newValue.label;
    this.selectedMetricNumber.suffix = '%';
    this.selectedMetricNumber.label = 0;

    this.alertMethods = this._rule.ruleType == 'tasks' ? ['Email', 'Slack'] : ['Email', 'SMS text message', 'Slack'];
    this.selectedAlertMethod = 'Select method';

    switch (this.selectedMetric) {
      case 'LOI':
      case 'IR':
        break;
      case 'Manual QCs':
        this.selectedMetricNumber.label = this.surveySettings?.dropoutRate || 0;
        break;
      case 'DAR':
        this.selectedMetricNumber.label = 80;
        break;
      case 'DOR':
        this.selectedMetricNumber.label = this.surveySettings?.dropoutRate || 0;
        break;
      case 'CID Dupes':
        this.selectedMetricNumber.label = 5;
      case 'CID Fraud':
        this.selectedMetricNumber.label = this.surveySettings?.cidFraudRate || 0;
        break;
    }

    this.applyMetricNumber();

    this.operators = [{label: 'Above', value: '>'}, {label: 'Below', value: '<'}];
    this.conditionChanged.emit({condition: 'operator', newValue: {value: null} });

    this.selectedOperator = 'Select';
    this.conditionChanged.emit({condition: 'metric', newValue});
  }

  applyMetricNumber() {
    let newValue = this.selectedMetricNumber;
    newValue.value = newValue.label;
    this.conditionChanged.emit({condition: 'metric-number', newValue});
  }

  applyOperator(newValue) {
    this.selectedOperator = newValue.label;
    this.conditionChanged.emit({condition: 'operator', newValue});
  }

  applyAlertMethod(newValue) {
    this.selectedAlertMethod = newValue;

    let phone = this.auth.getUser().phone;
    let slackUsername = this.auth.getUser().social.slackUsername;

    if (newValue == 'SMS text message' || newValue == 'Slack') {

      if (newValue == 'SMS text message') {
        if (!phone) {
          this.selectedAlertMethod = 'Email';
          this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
          this.alertify.error("There is no Mobile in your profile");
        }
      }
      else {
        if (!slackUsername) {
          this.selectedAlertMethod = 'Email';
          this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
          this.alertify.error("There is no Slack Username in your profile");
        }
      }
    }

    this.conditionChanged.emit({condition: 'alertMethod', newValue});
  }

  handleFields(type) {
    if (type === 'number') {
      this.applyMetricNumber();
    }
  }


}
