
@if (showChat) {
  <div class="chat-container nav-font14">
    <div class="header border-bottom d-flex align-items-center nav-font14">
      <i class="icon fak fa-ai-chatbot"></i>
      <div class="grey5 font-weight-bold header-text">NavigatOR Assistant <span class="grey4 font-weight-normal ml-2">AI powered chat</span></div>
      <span (click)="toggleChat()" class="far fa-times grey4 ml-auto nav-font18 p-1"></span>
    </div>
    <div class="chat-box-container" [style.height]="'calc(100% - ' + chatBoxHeight + 'px)'">
      <div class="chat-box" #chatBox [class.notOverflowing]="!isOverflowing">
        <div class="grey5 text-center mt-4 mb-2 welcome-message">Welcome to NavigatOR Assistant!<br>Ask me anything about NavigatOR.</div>
        
        <div class="messages-container">
          <div class="messages">
            @for (msg of messages; track msg) {
              <div class="mt-2" class="message" [ngClass]="{'user': msg.type === 'user', 'bot': msg.type === 'bot'}" [class.mb-2]="$last && !waitingForResponse">
                @if (msg.type === 'bot') {
                  <span class="innerHTML" innerHtml="{{msg.text}}"></span>
                }
                @else {
                  {{ msg.text }}
                }
              </div>
            }
            @if (waitingForResponse) {
              <div  class="message bot waitingForResponse my-2">
                <div class="typing">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                </div>
              </div>
            }
          </div>
          

          @if (nextQuestions.length > 0) {
            <div class="suggestion-container" [class.pb-3]="messages.length > 0">
              @for (q of nextQuestions; track q) {
                <div class="mt-2" class="suggestion mr-2" (click)="selectSuggestion(q)">
                  {{ q }}
                </div>
              }
            </div>
          }
        </div>
        

        
      </div>
    </div>
    
    <div class="chat-input d-flex align-items-center" [class.h-100]="rowCount>4">
      <textarea #textArea
        [(ngModel)]="userMessage" 
        maxlength="450" 
        [rows]="1"

        spellcheck="false"
        (ngModelChange)="getTextareaRowCount()" 
        (keydown.enter)="handleEmptyInput($event)" 
        (keydown.space)="handleEmptyInput($event)" 
        (keyup.enter)="sendMessage()" 
        placeholder="Type your message...">
      </textarea>
      <div class="btn-container text-center ml-2">
        <button class="send-message-btn" [class.hasMessage]="userMessage.length > 0 && !waitingForResponse" (click)="sendMessage()">
          <i class="icon fak fa-send-plane"></i>
        </button>
        <span class="grey2 nav-font10 d-block mt-1" [class.text-error]="userMessage.length == 450">{{userMessage.length}}/450</span>
      </div>
    </div>
  </div>
}

<div class="chat-icon-container" [class.chatOpen]="showChat" (click)="toggleChat()">
  <i class="icon fak fa-ai-chatbot"></i>
</div>

 <!-- <div class="chat-container">
  <div class="chat-box">
    <div *ngFor="let msg of messages" class="message">
      {{ msg }}
    </div>
    <input [(ngModel)]="userMessage" (keyup.enter)="sendMessage()" placeholder="Type a message..." />
    <button (click)="sendMessage()">Send</button>
  </div>

</div> -->