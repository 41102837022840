export interface QCFlowDetailsResponse {
    canImport: boolean;
    qcFlowDetails: QCFlowDetails;
}

export interface QCFlowDetails {
    projectId: string;
    settings: QCFlowSettings;
    status: QCFlowStatus;
}

export interface QCFlowSettings {
    columns: Array<QCFlowFileColumn>;
    rules: Array<QCFlowRule>;
    enhanceWithAI: boolean;
    showMyAssessment: boolean;
    decipherAPIDetails:DecipherAPI;
    responseidMappings: ResponseIDMappings[];
}

export interface QCFlowStatus {
    currentStatus: string;
    statusReason: string;
    respondentCount: number;
    respondentProcessedCount: number;
    acceptedCount: number;
    rejectedCount: number;
    fileHistory: Array<QCFlowFile>;
    reviewers: Array<QCFlowReviewers>;
    lastReconciled: string;
}
export interface DecipherAPI {
    surveyId: string;
    apiKey: string;      
}
export interface QCFlowReviewers {
    id: string;
    name: string;
}

export interface ResponseIDMappings {
    colName: string;
    questionId: string;
}
export interface QCFlowFile {
    id: string;
    fileName: string;
    submittedById: string;
    submittedByName: string;
    submittedByImage: string;
    submittedOn: string;
    rowCount: number;
    isDeleted: boolean;
    ruleCheckCompleted?: boolean;
}
export interface QCFlowFileColumn {
    id: string;
    index: number;
    text: string;
    type: string;
    selected: boolean;
    expand: boolean;
}
export interface QCFlowFileSecondRow {
    index: number;
    text: string;
}

export interface QCFlowRule {
  id: string;
  name: string;
  status: string;
  logic: string; // 'AND' | 'OR'
  checks?: Array<QCFlowRuleCheck>;
}

export interface QCFlowRuleCheck {
  metric: string;
  operator: string;
  value: string;
  questionId: string;
  questionCount: string;
}
