<div class="btn-group w-100" dropdown #dropdown="bs-dropdown"  container="body">
  <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container py-0">
    <span class="nav-font14 cursor-default">{{getCurrent()}}</span>
    <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
      <i class="far grey4 float-right nav-font16 ml-auto fa-angle-down"></i>
    </div>
  </div>

  <ul *dropdownMenu class="dropdown-menu py-0 nav-font14 m-0 w-100">
    @for (item of values; track item; let idx = $index) {
    <li role="menuitem" class="grey4 nav-font14 d-flex align-items-center px-0"
      (click)="applyValue($event, item)">
      <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
        {{ item.name }}
      </div>
    </li>
    }
  </ul>
</div>