import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService, AuthService, QuotaQuestion, ProjectService } from 'core';
import {  BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { QuotaService } from 'projects/core/src/services/quota.service';
import { v4 as uuidv4 } from 'uuid';
import { J } from '@fullcalendar/core/internal-common';


@Component({
  selector: 'app-quota-questions',
  templateUrl: './quota-questions.component.html',
  styleUrls: ['./quota-questions.component.scss']
})
export class QuotaQuestionsComponent implements OnInit {

  instanceId: string;
  locations: any[];
  model: QuotaQuestion;
  questionList: any[] = [];
  deleteQuestion: any;
  questionTypes: string[];
  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  layout = [
    {label: 'Question', id: 'question'},
    {label: 'Category', id: 'category'},
    {label: 'Type', id: 'type'},
    {label: 'Edit', id: 'action', type: 'actions-inline'},
  ];
  actions = [{label: 'Edit', type: 'edit', icon: 'pen-square'}]; //{label: 'Delete', type: 'delete', icon: 'trash-alt'} 

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private alertify: AlertifyService,
    private quotaService: QuotaService,
    private projectService: ProjectService,
    public auth: AuthService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.loadData();
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
        this.router.navigate(['/quota-edit'], {state: {data: dataEdit }});      
    }
    if (action.type === 'delete') {
      this.deleteQuestion = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  loadData() {
    this.questionList = []
    this.questionTypes = [ 'Gender', 'Age', 'Region', 'HouseholdIncome', 'Ethnicity', 'Hispanic'] //'State', 'Division'
    this.quotaService.ListQuestions(this.instanceId).subscribe( data => {
      data.forEach(element => {

        if (this.questionTypes.includes(element.type)) {
          var index = this.questionTypes.indexOf(element.type);
          element.order = index;
          if (element.type == 'HouseholdIncome') element.defaults.sort((a, b) => (a.min > b.min) ? 1 : -1)
          this.questionList.push(element)
          this.questionTypes.splice(index, 1);
        }
      })

      var missingQuestions = this.questionTypes.length > 0;
      if (missingQuestions) { 
        this.quotaService.CloneQuotaFromORInstance(this.instanceId).subscribe( data => {
          if (data) {
            this.loadData();
          }
        });
      }
      else {
        this.sort(this.questionList)
        this.questionList = JSON.parse(JSON.stringify(this.questionList));
      }
      
      this.sort(this.questionList);
    }, error => {
       this.alertify.error('Unable to fetch Quota Questions');
    });
  }

  sort(array) {

    array.sort((a, b) => {

      let comparison = 0;
      if (a.order > b.order) {
        comparison = 1;
      } else if (a.order < b.order) {
        comparison = -1;
      }
      return comparison;
    });
  }

  addDefaultQuestion (questionData) {
    this.quotaService.AddQuestion(questionData.value).subscribe( data => {
      this.model = questionData.value
      if (this.model) {
        this.questionList.push(this.model);
        this.sort(this.questionList);
        this.questionList = JSON.parse(JSON.stringify(this.questionList));
      }

    }, error => {
    },
    () => {
    });
  }

  confirmDelete() {
    this.quotaService.DeleteQuestion(this.deleteQuestion).subscribe( data => {

    }, error => {
       this.alertify.error('Unable to delete Quota Question');
       this.openModalRef.hide();
    },
    () => {
      this.alertify.success('Quota Question deleted successfully');
      this.openModalRef.hide();
      this.loadData();
    });
  }

}
