import { Injectable } from '@angular/core';
import { __values } from 'tslib';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/core/src/environments/environment';
import { Observable } from 'rxjs';
import { ChatBotRequest } from '../models/chatbot-request';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  baseUrl = environment.apiEndpoint + '/support';
  constructor(private http: HttpClient) { }

  createIdea(formData: FormData, summary: string,description:string) {   
    return this.http.post(this.baseUrl + '/jiraissue', formData, { params: { subject: summary,idea:description } });
  }

  getChatBotResponse(request: ChatBotRequest): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/chatbot', request);
  }
  
  streamMessage(
    request: any,
    onMessage: (chunk: string) => void,
    onComplete?: () => void // Added completion callback
  ) {
    const url = `${this.baseUrl}/chatbot/stream`;
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN_' + environment.apiEndpoint)
      },
      body: JSON.stringify(request), // Send the request body as JSON
    })
      .then(async (response) => {
        if (!response.body) {
          throw new Error('Response body is empty');
        }
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
  
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              console.log('Stream completed.');
              if (onComplete) onComplete(); // Call onComplete callback
              break;
            }
  
            const chunk = decoder.decode(value, { stream: true });
            console.log('Received chunk:', chunk);
            onMessage(chunk);
          }
        } catch (error) {
          console.error('Error while reading the stream:', error);
        }
      })
      .catch((error) => {
        console.error('Error while streaming:', error);
      });
  }
  
}
