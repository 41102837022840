import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { SurveyTesterComments, SurveyTesterProject, SurveyTesterSessionActivity } from 'core';

@Injectable({
  providedIn: 'root'
})
export class SurveyTesterService {
  baseUrl = environment.apiEndpoint + '/surveytester/';
  tzoffset = new Date().getTimezoneOffset() * -1;
  constructor(private http: HttpClient) { }

  getProjectInfo(surveyId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + surveyId + '/getprojectsinfo');
  }


  getProjectdetails(surveyId: string, projectId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + surveyId + '/getProjectdetails/' + projectId);
  }
  finalizeSurvey(id: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + id + '/FinalizeSurveyTesterProject', id);
  }

  reopenSurvey(id: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + id + '/ReopenSurveyTesterProject', id);
  }

  getComments(surveyId: string, forceRefresh = false): Observable<any> {
    const hasLocalComments = localStorage.getItem('comments') !== null;
    if (hasLocalComments && !forceRefresh) {
      return of(JSON.parse(localStorage.comments));
    } else {
      return this.http.get<any>(this.baseUrl + surveyId + '/'+ this.tzoffset + '/GetSurveyTesterComments').pipe(
        map((response: any) => {
          if (response) {
            localStorage.setItem('comments', JSON.stringify(response));
          }
          return response;
        })
      );
    }
  }

  exportComments(surveyId: string, forceRefresh = false): Observable<any> {
    const hasLocalComments = localStorage.getItem('comments') !== null;
    if (hasLocalComments && !forceRefresh) {
      return of(JSON.parse(localStorage.comments));
    } else {
      return this.http.get<any>(this.baseUrl + surveyId + '/'+ this.tzoffset + '/ExportSurveyTesterComments').pipe(
        map((response: any) => {
          if (response) {
            localStorage.setItem('comments', JSON.stringify(response));
          }
          return response;
        })
      );
    }
  }


  getProjectSessions(surveyId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + surveyId + '/GetSurveyTesterSessions');
  }

  getProjectHomepageComments(surveyId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + surveyId + "/" + this.tzoffset + '/GetProjectHomepageComments',);
  }
  // getSurveyId(surveyId: string): Observable<any> {
  //   return this.http.get<any>(this.baseUrl + surveyId + '/GetSurveyTesterSessions');
  // }

  getSessionActivities(sessionId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + sessionId + '/GetSurveyTesterSessionActivity');
  }

  reply(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SaveReply', model);
  }

  approve(commentId: string, sessionId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + sessionId + '/ApproveComment', { id: commentId });
  }

  updateReplyViewDate(commentId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'UpdateReplyViewDate/' + commentId, {});
  }

  cancel(commentId: string, sessionId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + sessionId + '/CancelComment', { id: commentId });
  }

  sendForApproval(commentId: string, sessionId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + sessionId + '/SendForApproval', { id: commentId });
  }

  reopen(commentId: any, sessionId: string): Observable<SurveyTesterComments> {
    return this.http.post<SurveyTesterComments>(this.baseUrl + sessionId + '/ReopenComment', { id: commentId });
  }

  saveComment(model: any, sessionId: string): Observable<SurveyTesterComments> {
    return this.http.post<SurveyTesterComments>(this.baseUrl + sessionId + '/AddComment', model);
  }
  updateComment(model: any, removeAttachments: boolean = false): Observable<SurveyTesterComments> {
    return this.http.post<SurveyTesterComments>(this.baseUrl +'UpdateComment?removeAttachments=' + removeAttachments, model);
  }
  saveSession(surveyId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SaveSessions/' + surveyId + '', {});
  }

  saveActivity(model: any): Observable<SurveyTesterSessionActivity> {
    return this.http.post<SurveyTesterSessionActivity>(this.baseUrl + 'SaveActivity', model);
  }

  getSurveyTesterCommentsByQuestionId(surveyId: string, questionId: string, answerId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + surveyId + '/' + questionId + '/' + answerId +  '/'+ this.tzoffset + '/GetSurveyTesterCommentsByQuestionId');
  }
  getSurveyId(model: any): Observable<SurveyTesterProject> {
    return this.http.post<SurveyTesterProject>(this.baseUrl + 'GetSurveyPlatformDetails', model);
  }


  removeGuest(Id: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + 'RemoveGuest/' + Id);
  }

  addGuest(guest: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'AddGuest', guest);
  }

  updateNotes(surveyId, notes: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + surveyId + '/UpdateNotes', { sVal: notes });
  }

  sendGuestLink(guestId, url: string, surveyId: string): Observable<any> {
    const payload = { sVal: url, sVal2: surveyId }
    return this.http.post<any>(this.baseUrl + 'SendGuestLink/' + guestId, payload);
  }

  getSurveyProject(url: string, selectedPlatform: string) {
    let suveyProject = {} as SurveyTesterProject;
    let firstSplit = url.split('?');
    suveyProject.platform = selectedPlatform;
    suveyProject.platformProjectId = firstSplit[0];
    suveyProject.url = url;
    return suveyProject;
  }

  isLinkExpired(linkId: any): Observable<any> { return this.http.post<any>(this.baseUrl + 'isLinkExpired/' + linkId, {}); }

  /* sort order
    1. string only
    2. string with number
    3. number with string
    4. number only
  */
  orderComments(comments: any[]): any[] {
    return comments.sort((a, b) => {
        const regexStringOnly = /^[A-Za-z_-]+$/; // Matches string only
        const regexStringWithNumber = /^[A-Za-z_-]+\d+(_\d+)?$/; // Matches string + number (optional sub-number)
        const regexNumberWithString = /^\d+[A-Za-z_-]+$/; // Matches number + string
        const regexNumberOnly = /^\d+$/; // Matches number only

        // Determine the category of a given string
        const getCategory = (str: string): number => {
            if (regexStringOnly.test(str)) return 1; // String only
            if (regexStringWithNumber.test(str)) return 2; // String + number
            if (regexNumberWithString.test(str)) return 3; // Number + string
            if (regexNumberOnly.test(str)) return 4; // Number only
            return 5; // Fallback category
        };

        const questionIdA = a.questionId;
        const questionIdB = b.questionId;

        const categoryA = getCategory(questionIdA);
        const categoryB = getCategory(questionIdB);

        // Sort by category first
        if (categoryA !== categoryB) {
            return categoryA - categoryB;
        }

        // Sorting within the same category
        if (categoryA === 1 || categoryA === 3) {
            // Alphabetical sorting
            return questionIdA.localeCompare(questionIdB);
        }

        if (categoryA === 2 || categoryA === 4) {
            // String + number or number-only sorting
            const regex = /^([A-Za-z_-]*)(\d*)(?:_(\d+))?$/;
            const [, letterA = "", numA = "0", subNumA = "0"] = questionIdA.match(regex)!;
            const [, letterB = "", numB = "0", subNumB = "0"] = questionIdB.match(regex)!;

            // Compare main string part
            if (letterA !== letterB) {
                return letterA.localeCompare(letterB);
            }

            // Compare main numeric part
            const diffNum = parseInt(numA || "0", 10) - parseInt(numB || "0", 10);
            if (diffNum !== 0) return diffNum;

            // Compare sub-number part
            return parseInt(subNumA || "0", 10) - parseInt(subNumB || "0", 10);
        }

        return 0;
    });
  }

}
