import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectTask, ScheduleTemplate } from 'core';
@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  static STATUS = {
    COMPLETE: 'Complete',
    PENDING: 'Pending'
  }

  baseUrl = environment.apiEndpoint + '/schedule/';

  constructor(private http: HttpClient) { }

  GetProjectSchedule(id, filter): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'project/'+ id, filter ?? {
      assignedToId: [],
      filterList: [],
      statusList: [],
      projectId: id,
      bidNumber: null,
      textFilter: '',
      orderBy: '',
      orderDirection: ''
    });
  }
  GetProjectScheduleSummary(bidNumber): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'summary/sidebar/' + encodeURI(bidNumber));
  }
  GetFamilySchedule(bidNumber): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'summary/' + encodeURI(bidNumber));
  }
  GetAllTasks(filter: any = {}): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'task', filter);
  }
  GetTask(id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'task/' + id);
  }
  GetAllProjectsTasks(projectIds: string[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectTasks', projectIds);
  }
  CreateProjectTask(projectId:string, stageId:string, task:ProjectTask) {
    return this.http.post<any>(`${this.baseUrl}${projectId}/stage/${stageId}/task`, task);
  }
  ToggleStageActive(stageId:string, isActive:boolean) {
    return this.http.put<any>(`${this.baseUrl}stage/${stageId}/` + ((isActive) ? 'activate' : 'deactivate'), {});
  }
  SaveProjectTask(id: string, task:ProjectTask, updateFutureTasks: boolean, updateAll: boolean) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/${updateFutureTasks}/${updateAll}`, task);
  }
  SaveProjectTaskAssignees(id: string, assignees: any[]) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/assignees`, assignees);
  }
  SaveProjectTaskOrder(tasks: ProjectTask[]) {
    return this.http.put<any>(`${this.baseUrl}taskorder`, tasks);
  }
  DeleteTask(id: string) {
    return this.http.delete<any>(`${this.baseUrl}task/${id}`);
  }
  CompleteTask(id: string) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/complete`, {});
  }
  UnCompleteTask(id: string) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/uncomplete`, {});
  }
  BulkReassignTasks(assignees: any[], tasks: any[], projectId: string) {
    return this.http.put<any>(`${this.baseUrl}tasks/${projectId}/reassign`, { assignees, tasks });
  }
  BulkChangeDate(id: string, taskIds,date:Date,type:any,shiftdays:string) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/bulkdate`,{ sVal2: taskIds,dVal:date,nval:type,sval:shiftdays });
  }

  CloneSchedule(id: string,cloneProjectId,date:Date) {
    return this.http.put<any>(`${this.baseUrl}task/${id}/cloneSchedule`,{sVal2: cloneProjectId,dVal:date });
  }

  CloneTask(projectId, taskId) {
    return this.http.post<any>(`${this.baseUrl}${projectId}/clone/task/${taskId}`, {});
  }

  ImportTasks(projectId: string, awsfileName: string, actualFileName: string, url: string): Observable<any>
   {
    return this.http.post(this.baseUrl + projectId + '/task/importTask/' + awsfileName + '/' + actualFileName + '/' + encodeURIComponent(url), null);
   }
   BulkDeleteTask(projectId: string, taskIds:any) {
    return this.http.put<any>(`${this.baseUrl}task/${projectId}/bulkdelete`,taskIds);
  }
  CreateScheduleTemplate(template: ScheduleTemplate) {
    return this.http.post<any>(`${this.baseUrl}template`, template);
  }
  GetAllTemplates(projectTypeId) {
    return this.http.get<any>(`${this.baseUrl}${projectTypeId}/template`);
  }
  GetTemplateAsSchedule(templateId) {
    return this.http.get<any>(`${this.baseUrl}template/${templateId}`);
  }

  DeleteTemplate(templateId) {
    return this.http.delete<any>(`${this.baseUrl}template/${templateId}`);
  }
  ApplyTemplate(projectId, templateId, overrideExisting, assignAllToMe) {
    return this.http.post<any>(`${this.baseUrl}template/${templateId}/${projectId}/${overrideExisting}/${assignAllToMe}`, {});
  }

  SaveReoccurrenceSettings(taskId, settings, updateAll) {
    return this.http.post<any>(`${this.baseUrl}task/${taskId}/reoccurrence/${updateAll}`, settings);
  }
  DeleteReoccurringTasks(taskId, settingsId, updateAll) {
    return this.http.put<any>(`${this.baseUrl}task/${taskId}/reoccurrence/${settingsId}/delete/${updateAll}`, {});
  }

}
