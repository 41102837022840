import { Injectable } from '@angular/core';
import { Project, ProjectDedupeStatus, ProjectTeam, ProjectTag, ProjectSurveyPartner } from 'core';
import { UtilsService } from '../services/utils.service';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseUrl = environment.apiEndpoint + '/project/';

  constructor(private http: HttpClient, public utils: UtilsService) { }

  private switchSectionFromModalSubject = new Subject<void>();
  switchSectionFromModal$ = this.switchSectionFromModalSubject.asObservable();

  // Call this method to switch to project section from a modal
  switchSection(evt: any) {
    this.switchSectionFromModalSubject.next(evt);
  }


  GetProjects(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'list', settings);
  }
  EnrichProjects(projects) {
    // Changes some of the data we get from the DB so that is more useful on angular side
    projects.forEach(project => this.EnrichProject(project));

    return projects;
  }
  EnrichProject(project) {

    // Changes some of the data we get from the DB so that is more useful on angular side
    project.pm = (project.projectManager == null)
      ? project.projectTeam === undefined
        ? null
        : project.projectTeam.find(x => x.projectRole == 'PM') !== undefined
          ? project.projectTeam.find(x => x.projectRole == 'PM').displayName
          : null
      : [{ name: project.projectManager }];

    project.pmEmail = project.projectTeam?.find(x => x.projectRole == 'PM')?.email;
    project.secondPm = project.secondaryManagers?.split(", ") || []

    project.accountOwner = (project.accountOwner == null)
      ? []
      : [{ name: project.accountOwner }];

    if (project?.vendorPms == null || project?.vendorPms?.length == 0) {
      project.vendorPms = [];
    }
    else {
      project.vendorPms = project.vendorPms?.split(',').map((e) => ({ name: e }));
    }

    project.totalDays = this.utils.daysBetween(new Date(project.fieldStartDate), new Date(project.fieldEndDate));
    project.totalDaysLeft = this.utils.daysBetween(new Date(), new Date(project.fieldEndDate));
    project.percentageDaysLeft = Math.ceil((project.totalDaysLeft / project.totalDays) * 100);
    if (project.percentageDaysLeft < 0) {
      project.percentageDaysLeft = 0;
    }
    if (project.percentageDaysLeft > 100) {
      project.percentageDaysLeft = 100;
    }
    project.percentageComplete = (project.complete * 100) / project.fullLaunchQuota;

    //Update GUIDS to be uppercase
    if (project.clientId != null) {
      project.clientId = project.clientId.toLowerCase();
    }
    project.projectSegments?.forEach(seg => {
      seg.projectSurveyPartners.forEach(partner => {
        partner.partnerId = partner.partnerId.toLowerCase();
      })
    });
    project = this.HandleHealthDetails(project);

    return project;
  }

  HandleHealthDetails(project) {
    
    project.health_icons = [];

    let msg = '';

    if (project.health <= 3) {
      project.health_icons.push({ icon: 'far fa-square-check nav-success', message: msg });
    } else if (project.health <= 8) {
      project.health_icons.push({ icon: 'far fa-triangle-exclamation nav-medium-risk', message: msg });
    } else {
      project.health_icons.push({ icon: 'far fa-triangle-exclamation nav-error', message: msg });
    }
    msg = '';

    // pace
    if (project.paceMetric < project.alertPaceMetric) {
      msg += 'Pace actual: ' + project.paceMetric + ', expected: ' + project.alertPaceMetric + ' ';
    }

    // ir
    const alertThreshold = .3;
    project.ir = (project.projectSegments == null) ? project.ir : project.projectSegments[0].projectIR;
    const irDifference = Math.abs(project.actualIR - project.ir);
    if (irDifference > (project.ir * alertThreshold)) {
      project.isAlertIR = true;
      if (msg.length > 0 && msg.slice(-2) !== '\n') msg += '\n'
      msg += 'IR actual: ' + project.actualIR + ', expected: ' + (project.ir || 0) + ' ';
    }
    
    // manual QCs
    var clientQCRate = 0;
    var metrics = project?.metrics;
    if (metrics) {
      clientQCRate = metrics.clientQC > 0 && metrics.complete > 0 ? Math.round(metrics.clientQC * 100 / (metrics.clientQC + metrics.complete)) : 0;
    }
    else {
      clientQCRate = project.clientQC > 0 && project.complete > 0 ? Math.round(project.clientQC * 100 / (project.clientQC + project.complete)) : 0;
    }
    if (clientQCRate > project.alertQCRate) {
      if (msg.length > 0 && msg.slice(-2) !== '\n') msg += '\n'
      msg += 'Manual QC Rate actual: ' + clientQCRate + ', expected: ' + (project.alertQCRate || 0) + ' ';
    }

    // cid fraud rate
    var entries = 0;
    var cleanIDRate = 0;
    if (metrics) {
      entries = (metrics.starts + metrics.cleanID + metrics.dupes + metrics.clientOverQuota + metrics.mobileBlock + metrics.navTerminate)
      cleanIDRate = metrics.cleanID > 0 ? Math.round(metrics.cleanID * 100 / entries) : 0;
    }
    else {
      entries = (project.starts + project.cleanID + project.dupes + project.clientOverQuota + project.mobileBlock + project.navTerminate)
      cleanIDRate = project.cleanID > 0 ? Math.round(project.cleanID * 100 / entries) : 0;
    }
    if (cleanIDRate > project.alertCIDFraudRate) {
      if (msg.length > 0 && msg.slice(-2) !== '\n') msg += '\n'
      msg += 'Fraud Rate actual: ' + cleanIDRate + ', expected: ' + (project.alertCIDFraudRate || 0) + ' ';
    }
    
    if (msg !== '') {
      project.health_icons.push({ icon: 'fas fa-bell text-info', message: msg });
    }
    return project;
  }

  GetProjectTypes(instanceId?: string, cache?: boolean, loadAll?: boolean, vendorId?: string, clientId?: string) {

    if (vendorId === undefined) {
      vendorId = null;
    }
    if (clientId === undefined) {
      clientId = null;
    }
    if (loadAll === undefined) {
      loadAll = null;
    }

    let url = this.baseUrl + "GetProjectTypes";;
    let params = new HttpParams();

    if (instanceId !== null) {      
      params = params.set('instanceId', instanceId);
    }
    if (loadAll !== null) {
      params = params.set('loadAll', loadAll);
    }
    if (vendorId !== null) {
      params = params.set('vendorId', vendorId);
    }
    if (clientId !== null) {
      params = params.set('clientId', clientId);
    }
    if (cache) {
      return this.http.get<any>(url, { headers: { cache: '1' }, params });
    }
    else {
      return this.http.get<any>(url, { params });
    }
  }

  SaveProjectType(instanceId: string, details: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'types/' + instanceId, details);
  }
  DeleteProjectType(typeId: string) {
    return this.http.delete<any>(this.baseUrl + 'types/' + typeId);
  }
  GetProjectTypeDetails(type: string) {
    return this.http.get<any>(this.baseUrl + 'type/' + type);
  }

  GetProjectListViews(instanceId?: string, cache?: boolean) {
    if (cache) {
      return this.http.get<any>(this.baseUrl + 'listviews/' + (instanceId ?? ''), { headers: { cache: '1' } });
    }
    else {
      return this.http.get<any>(this.baseUrl + 'listviews/' + (instanceId ?? ''));
    }
  }
  SaveProjectListView(instanceId: string, json: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'listviews/' + instanceId, json);
  }
  DeleteProjectListView(model: any) {
    return this.http.delete(this.baseUrl + 'listviews/' + model.id);
  }
  GetRelatedProjects(id: string) {
    return this.http.get<any>(this.baseUrl + id + '/related', { headers: { cache: '1' } });
  }
  GetOrderedRelatedProjects(id: string) {
    return this.http.get<any>(this.baseUrl + id + '/related/ordered', { headers: { cache: '1' } });
  }
  GetOrderedRelatedProjectsSummary(bidNumber: string) {
    return this.http.get<any>(this.baseUrl + encodeURI(bidNumber) + '/related/ordered/summary', { headers: { cache: '1' } });
  }
  GetRelatedByBidNumber(id: string, bidNumber: string) {
    return this.http.get<any>(this.baseUrl + id + '/relatedbybid/' + encodeURI(bidNumber), { headers: { cache: '0' } });
  }
  GetProject(id: string, vendorId?: string): Observable<Project> {
    return this.http.get<Project>(this.baseUrl + id + "/" + vendorId);
  }
  // GetProject(id: string, vendorId?: string, clientId?: string): Observable<Project> {
  //   let url = this.baseUrl;
  //   url = this.baseUrl + "getproject";
  //   let params = new HttpParams();
  //   if (id !== '') {
  //     params = params.set('id', id);
  //   }
  //   if (vendorId !== '') {
  //     params = params.set('vendorId', vendorId);
  //   }
  //   if (clientId !== '') {
  //     params = params.set('clientId', clientId);
  //   }
  //   return this.http.get<Project>(url, { params });
  // }

  DeleteProject(id: string) {
    return this.http.delete<any>(this.baseUrl + id);
  }

  CreateProject(model: any): Observable<Project> {
    return this.http.post<Project>(this.baseUrl, model);
  }
  SaveProject(projectId, model: any): Observable<Project> {
    return this.http.put<Project>(this.baseUrl + projectId, model);
  }
  GetEntryId(project, segment, partner): Observable<string> {
    return this.http.get<string>(this.baseUrl + 'entryid/' + project + '/' + segment + '/' + partner);
  }

  UpdateProjectValue(projectId: string, fieldId: string, value: any): Observable<any> {
    const field: any = { fieldId };
    if (typeof value === 'boolean') {
      field.bVal = value;
    } else if (typeof value === 'string') {
      field.sVal = value;
      if (!isNaN(field.sVal) && field.sVal !== '') {
        field.nVal = parseInt(field.sVal);
        field.fVal = field.nVal;
      }
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) field.nVal = value;
      field.fVal = value;
    } else if (value instanceof Date) {
      field.dVal = value;
    } else if (value instanceof Array) {
      if (value.every(i => typeof i === 'string')) {
        field.sVal3 = value;
      } else {
        field.lVal = value;
      }

    }
    return this.http.put<any>(this.baseUrl + projectId + '/field', field);
  }
  UpdateProjectTeam(projectId: string, projectTeam: ProjectTeam[]): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectId + '/team', projectTeam);
  }
  UpdateProjectTags(projectId: string, projectTags: string[]): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectId + '/tags', projectTags);
  }

  UpdateProjectDedupeStatus(projectId: string, statuses: ProjectDedupeStatus[]): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectId + '/dedupestatus', statuses);
  }

  UpdateProjectStatus(id: string, value: string): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/status', { fieldId: '', sVal: value });
  }
  UpdateProjectSurveyLinks(id: string, live: string, test: string, surveyPlatForm: string): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/surveylinks', { surveyLink: live, testSurveyLink: test, surveyPlatForm: surveyPlatForm });
  }

  ListProjectPartners(id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + id + '/partner');
  }
  AddProjectFieldTracking(id: string, model: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/fieldtracking', model);
  }
  DeletePartnerFromProject(id: string, partnerid: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + id + '/partner/' + partnerid);
  }
  SaveProjectPartner(id: string, partner: ProjectSurveyPartner, pms): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/partner', { partner: partner, pms: pms });
  }

  UpdateProjectPartnerStatus(id: string, partnerid: string, value: string): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/partner/' + partnerid + '/status', { fieldId: '', sVal: value });
  }
  UpdateProjectPartnerCPI(id: string, partnerid: string, value: number): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/partner/' + partnerid + '/cpi', { fieldId: '', fVal: value });
  }
  UpdateProjectSegmentCPI(id: string, segmentid: string, value: number): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/segment/' + segmentid + '/cpi', { fieldId: '', fVal: value });
  }
  UpdateAllProjectPartnerStatus(id: string, value: string): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/status/partners', { fieldId: '', sVal: value });
  }
  UpdateProjectPartnerAllocation(id: string, partnerid: string, value: number, soft: boolean): Observable<any> {
    if (soft) {
      return this.http.put<any>(this.baseUrl + id + '/partner/' + partnerid + '/allocation/soft', { fieldId: '', nVal: value });
    } else {
      return this.http.put<any>(this.baseUrl + id + '/partner/' + partnerid + '/allocation/full', { fieldId: '', nVal: value });
    }
  }

  UpdateProjectPartnerAllocationEnforcement(id: string, partnerid: string, enforce: boolean): Observable<any> {
    return this.http.put<any>(this.baseUrl + id + '/partner/' + partnerid + '/enforce/' + enforce, {});
  }

  BulkUpdateProjectPartnerAllocationEnforcement(projectId: string, partnerIds: string[], enforce: boolean): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectId + '/partner/enforce/' + enforce, partnerIds);
  }

  GetProjectParticipants(id, vendorId?: string) {
    const tzoffset = new Date().getTimezoneOffset() * -1;
    return this.http.get<any>(this.baseUrl + id + '/participants?tzoffset=' + tzoffset + "&vendorId=" + vendorId);
  }

  GetFamilyParticipants(bidNumber) {
    const tzoffset = new Date().getTimezoneOffset() * -1;
    return this.http.get<any>(this.baseUrl + 'summary/' + encodeURI(bidNumber) + '/participants?tzoffset=' + tzoffset);
  }

  GetBulkProjectUrls(listOfIds: Array<string>): Observable<any> {
    return this.http.post(this.baseUrl + 'urls', listOfIds);
  }

  UpdateQuestions(listOfQuestions: any[]): Observable<any> {
    return this.http.post(this.baseUrl + 'questions', listOfQuestions);
  }

  GetLocations(type: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'locations?type=' + type, { headers: { cache: '1' } });
  }

  DeleteProjectHealthConfig(id: string) {
    return this.http.delete<any>(this.baseUrl + 'healthconfig/' + id)
  }

  CreateProjectHealthConfig(instanceId: string, name: string, description: string, settings: string) {
    return this.http.post<any>(this.baseUrl + 'healthconfig', { instanceId: instanceId, configName: name, description: description, settings: settings });
  }

  GetAllProjectHealthConfigs(instanceId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'healthconfig/' + instanceId, {});
  }

  UpdateProjectHealthConfig(id: string, name: string, description: string, settings: string) {
    return this.http.put<any>(this.baseUrl + 'healthconfig/' + id, { configName: name, description: description, settings: settings })
  }

  CreateProjectWithThirdParties(id) {
    return this.http.post<any>(this.baseUrl + id + '/connector', {});
  }

  GetProjectStatusWithThirdParties(id) {
    return this.http.get<any>(this.baseUrl + id + '/connector/status');
  }

  GlobalSearch(searchWord: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'globalsearch?searchWord=' + searchWord);
  }

  CopySearch(searchWord: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'copysearch?searchWord=' + searchWord);
  }

  SavePartnerProjectNumber(projectid, partnerid, projectNum): Observable<any> {
    const field: any = { fieldId: 'projectnumber', sVal: projectNum };
    return this.http.put<any>(this.baseUrl + projectid + '/partner/' + partnerid + '/projectnumber', field);
  }
  SavePartnerProjectPms(projectid, partnerid, pms): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectid + '/partner/' + partnerid + '/pms', pms);
  }
  SavePartnerProjectRedirects(projectid, partnerid, links): Observable<any> {
    return this.http.put<any>(this.baseUrl + projectid + '/partner/' + partnerid + '/redirects', links);
  }

  setPerformanceCardView(settings) {
    localStorage.setItem('performanceCardViewSettings', JSON.stringify(settings));
  }
  getPerformanceCardView() {
    const settings = localStorage.getItem('performanceCardViewSettings');
    return (settings != null) ? JSON.parse(settings) : { showPreSurveyBreakdown: false, showInSurveyBreakdown: true, showKPIBreakdown: false, sortOrder: 'desc' };
  }

  generateEntryUrlParams(surveyLink: string, links: any) {
    // Get any params that need to be appended
    const regex = /#(.*?)#/gm;
    const matches = [];
    let match;
    let paramIdx = surveyLink.indexOf('?');

    if (paramIdx > 0) {
      const paramList = surveyLink.substr(paramIdx);
      while ((match = regex.exec(paramList))) { matches.push(match[1]); }
    }
    if (links.completeRedirectURL != null) {
      paramIdx = links.completeRedirectURL.indexOf('?');
      if (paramIdx > 0) {
        const paramList = links.completeRedirectURL.substr(paramIdx);
        while ((match = regex.exec(paramList))) { matches.push(match[1]); }
      }
    }
    if (links.qcRedirectURL != null) {
      paramIdx = links.qcRedirectURL.indexOf('?');
      if (paramIdx > 0) {
        const paramList = links.qcRedirectURL.substr(paramIdx);
        while ((match = regex.exec(paramList))) { matches.push(match[1]); }
      }
    }
    if (links.overQuotaRedirectURL != null) {
      paramIdx = links.overQuotaRedirectURL.indexOf('?');
      if (paramIdx > 0) {
        const paramList = links.overQuotaRedirectURL.substr(paramIdx);
        while ((match = regex.exec(paramList))) { matches.push(match[1]); }
      }
    }
    if (links.terminateRedirectURL != null) {
      paramIdx = links.terminateRedirectURL.indexOf('?');
      if (paramIdx > 0) {
        const paramList = links.terminateRedirectURL.substr(paramIdx);
        while ((match = regex.exec(paramList))) { matches.push(match[1]); }
      }
    }
    matches.sort().reverse();

    let params = '';
    const handled: any = { s2: true };
    matches.forEach(e => {
      // we ignore params we already handled or if they are built in parameters
      if (handled[e] === true || e === 'or1' || e === 'cleanid' || e === 'fraudscore' || e === 'orvid') return true;
      params = params + e + '=&';
      handled[e] = true;
    });

    // TO DO: PANELIST ID HARDCODED TO s2 HERE BUT WILL BE CONFIG EVENTUALLY
    return params + 's2=';
  }

  getVendorPricingForProject(projectId: string, vendorId?: string): Observable<any> 
  { 
    return this.http.get<any>(this.baseUrl + projectId + "/vendorpricing?vendorId=" + vendorId);
   }
  revertProjectStatusToClosedSuperAdmin(id: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + id + '/revertProjectStatusToClosed', {});
  }

  getMultiProjectDashboardChartInfo(bidNumber: string): Observable<any> { return this.http.get<any>(this.baseUrl + 'multiProjectDashboardChartInfo/' + encodeURI(bidNumber)); }
  getMultiProjectTasks(bidNumber: string): Observable<any> { return this.http.get<any>(this.baseUrl + 'multiProjectTasks/' + encodeURI(bidNumber)); }
  getMultiProjectTeamMembers(bidNumber: string): Observable<any> { return this.http.get<any>(this.baseUrl + 'multiProjectTeamMembers/' + encodeURI(bidNumber)); }

  getProjectInfoSummary(bidNumber: string): Observable<any> { return this.http.get<any>(this.baseUrl + 'info/summary/' + encodeURI(bidNumber)); }
}
