@if (_rule.ruleType == 'metrics' || _rule.ruleType == null) {
  <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">

    <div class="position-relative">

      @if (alertHistory) {
        <span class="condition-text nav-font14 grey5 position-relative font-weight-bold mr-1">When</span>
      }

      <!-- Scope Type -->
      <ng-template *ngTemplateOutlet="tplScopeType"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  have a/an  </span>

      <!-- Metrics -->
      <ng-template *ngTemplateOutlet="tplMetrics"></ng-template>

      <!-- Operators -->
      <ng-template *ngTemplateOutlet="tplOperators"></ng-template>

      <!-- Metric number -->
      <ng-template *ngTemplateOutlet="tplMetricNumber"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mr-1 font-weight-bold"> , send me a/an  </span>

      <!-- Method -->
      <ng-template *ngTemplateOutlet="tplAlertMethod"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  .  </span>

    </div>
  </span>
}

@if (_rule.ruleType == 'tasks') {

  <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">

    <div class="position-relative">

      @if (alertHistory) {
        <span class="condition-text nav-font14 grey5 position-relative font-weight-bold mr-1">If a task is</span>
      }

      <!-- Metrics -->
      <ng-template *ngTemplateOutlet="tplMetrics"></ng-template>

      @if (selectedMetric === 'Select' || selectedMetric === 'Assigned to me') {

        <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  For any of </span>

        <!-- Scope Type -->
        <ng-template *ngTemplateOutlet="tplScopeType"></ng-template>
      }

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">, send me a/an  </span>

      <!-- Method -->
      <ng-template *ngTemplateOutlet="tplAlertMethod"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  .  </span>

    </div>

  </span>
}

<!-- Template for Alert Method Dropdown -->
<ng-template #tplAlertMethod>
  @if (!_rule.editing) {
    <span class="condition-field d-inline-block clickable" style="max-width: 260px;" >
      {{ selectedAlertMethod }}
    </span>
  }

  @if (_rule.editing) {
    <div class="dropdown alertMethod condition-field d-inline-block clickable" dropdown #ddAlertMethod="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddAlertMethod.isOpen">
      <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center clickable">
        <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate clickable" [ngClass]="selectedAlertMethod === 'Select method' ? 'grey3' : 'grey5'">{{ selectedAlertMethod }}</span>
        <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddAlertMethod.isOpen"></i>
      </div>
      <ul *dropdownMenu class="dropdown-menu dropdown-method nav-font14 py-0" role="menu">
        @for (method of alertMethods; track method) {
          <li role="menuitem" class="mx-1 my-1 cursor-pointer clickable">
            <a class="dropdown-item px-1 pt-2 cursor-pointer d-flex clickable"
                [class.border-bottom]="($index+1) < alertMethods?.length"
                [class.slack]="method === 'Slack' && anyWithoutSlackUsername"
                (click)="(method === 'Slack' && anyWithoutSlackUsername) ? null : applyAlertMethod(method)">{{method}}
            </a>
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<!-- Template for ScopeType Dropdown -->
<ng-template #tplScopeType>
  @if (!_rule.editing) {
    <span class="condition-field d-inline-block" style="max-width: 260px;" >
      {{ selectedScope }}
    </span>
  }

  @if (_rule.editing) {
    <div class="dropdown alertScopeType condition-field d-inline-block" dropdown #ddScope="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddScope.isOpen">
      <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
        <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedScope === 'Select group' ? 'grey3' : 'grey5'">{{ selectedScope }}</span>
        <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddScope.isOpen"></i>
      </div>
      <ul *dropdownMenu class="dropdown-menu dropdown-scope nav-font14 py-0 scrollbar overflow-y" role="menu">
        @for (scope of scopeTypes; track scope) {
          <li role="menuitem" class="mx-1 my-1 cursor-pointer">
            <a class="dropdown-item px-1 pt-2 border-bottom cursor-pointer d-flex" [class.border-bottom]="($index+1) < scopeTypes?.length" (click)="applyScope(scope)">{{scope.label}}</a>
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<!-- Template for Metrics Dropdown -->
<ng-template #tplMetrics>
  @if (!_rule.editing) {
    <span class="condition-field d-inline-block" style="max-width: 260px;" >
      {{ selectedMetric }}
    </span>
  }

  @if (_rule.editing) {
    <div class="dropdown alertMetric condition-field d-inline-block ml-1" dropdown #ddMetric="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddMetric.isOpen">
      <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
        <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedMetric === 'Select metric' || selectedMetric === 'Select' ? 'grey3' : 'grey5'">{{ selectedMetric }}</span>
        <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddMetric.isOpen"></i>
      </div>
      <ul *dropdownMenu class="dropdown-menu dropdown-metrics nav-font14 py-0" role="menu">
        @for (metric of metricTypes; track metric) {
          @if (metric != 'Quota cells' || _rule?.scopeType === 'Project') {
            <li role="menuitem" class="mx-1 my-1 cursor-pointer">
              <a class="dropdown-item px-1 pt-2 cursor-pointer d-flex" [class.border-bottom]="($index+1) < metricTypes?.length" (click)="applyMetric(metric)">{{metric.label}}</a>
            </li>
          }
        }
      </ul>
    </div>
  }
</ng-template>

<!-- Template for Operators Dropdown -->
<ng-template #tplOperators>
  @if (selectedMetric === 'Quota cells') {
    <span class="condition-text nav-font14 grey5 position-relative ml-2 font-weight-bold">have reached</span>
  }
  @else {
    @if (!_rule.editing) {
      <span class="condition-field d-inline-block ml-1" style="max-width: 260px;" >
        {{ selectedOperator }}
      </span>
    }

    @if (_rule.editing) {
      <div class="dropdown alertOperators mx-2 condition-field d-inline-block" dropdown #ddOperator="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddOperator.isOpen">
        <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
          <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedOperator === 'Select' ? 'grey3' : 'grey5'">{{ selectedOperator }}</span>
          <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddOperator.isOpen"></i>
        </div>
        <ul *dropdownMenu class="dropdown-menu dropdown-operators nav-font14 py-0" role="menu">
          @for (operator of operators; track operator) {
            <li role="menuitem" class="mx-1 my-1 cursor-pointer">
              <a class="dropdown-item px-1 pt-2 border-bottom cursor-pointer d-flex" [class.border-bottom]="($index+1) < operators?.length" (click)="applyOperator(operator)">{{operator.label}}</a>
            </li>
          }
        </ul>
      </div>
    }
  }
</ng-template>

<!-- Template for Metric number-->
<ng-template #tplMetricNumber>
  @if (selectedMetric === 'Quota cells') {
    <span class="condition-text nav-font14 grey5 position-relative mr-2 font-weight-bold"> their limit</span>
  }
  @else {
    <span class="condition-field ml-1" [class.editable]="_rule.editing">
      @if (!_rule.editing) {
        <span class="condition-field d-inline-block" style="max-width: 260px;">{{selectedMetricNumber.label}}@if (selectedMetricNumber.value > -1){{{selectedMetricNumber.suffix}}}</span>
      }
      @if (_rule.editing) {
        <div class="d-inline-block metric-number">
          <div class="input-group">
            <input type="text" mask="99999"
            [patterns]="integerPattern"
            [(ngModel)]="selectedMetricNumber.label"
            (mouseleave)="handleFields('number');"
            (blur)="handleFields('number');"
            (focus)="$event.target.select()"
            class="form-control form-control-nav form-control-sm d-inline text-right">
            <div class="input-group-append d-flex align-items-center border pr-2">
              <span class="bg-white font-weight-normal cursor-default">%</span>
            </div>
          </div>
        </div>
      }
    </span>
  }

</ng-template>





