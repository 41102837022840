import { Injectable } from '@angular/core';
import { RoleUser, User,SupportAccessRequest } from 'core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.apiEndpoint + '/user/';

  constructor(private http: HttpClient) { }

  GetUsers(forceRefresh = false): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'users', {
      headers : {cache : '1', refresh: forceRefresh? '1':'0' }
    });
  }

  GetInstanceUsers(instanceId: string, internalOnly: boolean = false): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'users/' + instanceId + '?internalOnly='+ internalOnly);
  }

  DeleteUser(id: string) {
    return this.http.delete(this.baseUrl  + id);
  }

  Register(model: any): Observable<User> {
    return this.http.post<User>(this.baseUrl + '', model);
  }

  EditUser(model: any): Observable<User> {
    return this.http.put<User>(this.baseUrl + 'user/' + model.id, model);
  }

  EditAccount(model: any): Observable<User> {
    return this.http.put<User>(this.baseUrl + 'account/' + model.id, model);
  }

  SnoozeSystemAlerts(userId: string, snooze: boolean): Observable<User> {
    return this.http.put<User>(this.baseUrl + 'snoozeSystemAlerts/' + userId, snooze );
  }

  InviteUser(id:string){
    return this.http.get<User[]>(this.baseUrl + 'invite/' + id);
  }
  getEditUser() {
    return JSON.parse(localStorage.getItem('userEdit'));
  }

  saveEditUser(user: User) {
    localStorage.setItem('userEdit', JSON.stringify(user));
  }

  GetAllUserRoles(userId: string): Observable<RoleUser[]> {
    return this.http.get<RoleUser[]>(this.baseUrl + 'roles/' + userId);
  }

  getImpersonationUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'impersonationusers/' );
  }
  sendSupportEmail(model: any) {
    return this.http.put<User>(this.baseUrl + 'sendSupportAccess/' + model.id, model);
  }
  GetSupportRequest(userId: string): Observable<SupportAccessRequest> {
    return this.http.get<SupportAccessRequest>(this.baseUrl + 'getSupportAccess/' + userId);
  }
  saveSupportRequest(model: any) {
    return this.http.put<User>(this.baseUrl + 'saveSupportRequest', model);
  }
}
