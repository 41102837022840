import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RuleHeader } from 'core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SystemAlertsService {

  baseUrl = environment.apiEndpoint + '/SystemAlerts';

  constructor(private http: HttpClient) {}

  GetAlerts(): Observable<RuleHeader[]> {
    return this.http.get<RuleHeader[]>(this.baseUrl  + '/alerts');
  }

  AddAlert(model: any): Observable<RuleHeader> {
    return this.http.post<RuleHeader>(this.baseUrl + '/alert', model);
  }

  EditAlert(model: any): Observable<RuleHeader> {
    return this.http.put<RuleHeader>(this.baseUrl + '/alert', model);
  }

  DeleteAlert(id: any) {
    return this.http.delete(this.baseUrl + '/alert/' + id);
  }

}
