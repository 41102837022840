<div class="row">
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">Manual QC Rate Consumer </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateConsumer">
    </div>
  </div>
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">Manual QC Rate Health Care </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateHealthCare">
    </div>
  </div>
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">Manual QC Rate B2B </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateB2B">
    </div>
  </div>
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">Dropout Rate </p>
    <div class="input-group">
      <input type="number"  class="form-control form-control-sm" [(ngModel)]="settingsModel.dropoutRate">
    </div>
  </div>
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">Pace Metric </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.paceMetric">
    </div>
  </div>
  <div class="col-4 pb-3">
    <p class="text-secondary mb-1">CID-Fraud Rate </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.cidFraudRate">
    </div>
  </div>
</div>
<hr>
<div class="row float-right">
  <div class="col">
    <button type="button" class="btn or-primary-bg text-white" (click)="saveSettings()"> Save</button>
  </div>
</div>


