export interface SecurityKey {
  id: string;
  instanceId: string;
  name: string;
  value: string;
  baseURl: string;
  apiEndpoint: string;
  credentials: string;
  type: string;
}
