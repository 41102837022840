import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  @Input() url: string;
  @Input() name: string;
  @Input() names = [];
  @Input() showRole = true;
  @Input() icon: string;
  @Input() showInitials: boolean = false;
  @Input() type = 'circle';
  @Input() size = 'medium';
  @Input() selected = false;
  @Input() showOnHover = false;
  @Input() showPlus = false;

  constructor() { }

  ngOnInit() {
    // this.url = 'https://navigator-images.s3.us-east-2.amazonaws.com/71f7dbd2-a704-4a19-aea2-855d91694577.jpeg';
    // this.icon = 'fa-user-check';
  }

  getInitials(name) {
    if(name == null || name == undefined || name == '' || typeof name != 'string') return '';
    return name.split(' ').map(x => x.charAt(0)).join('').substr(0, 2).toUpperCase();
  }

  getTooltip(item) {
    var role = '';
    if (this.showRole) {
      role = '(' + (
          item?.type == 'secondPm' ? 'Secondary PM' :
          item?.type == 'accountOwner' ? 'Account Owner' :
          item?.type == 'pm' ? 'PM' :
          item?.type == 'programmer' ? 'Programmer' : // Added condition for 'programmer'
          'N/A'
      ) + ')';
  }
    return `${item?.name} ${role}`;
  }

  getTooltips(names) {
    return names.slice(1).map(x => this.getTooltip(x)).join(', ');
  }

}
