import { Address } from './address';
import { Social } from './social';
import { Access } from './access';

export interface User {
    id: string;
    name: string;
    instanceId: string;
    instanceName: string;
    roles: string[];
    firstName: string;
    lastName: string;
    title?: string;
    managerId?: string;
    managerName?: string;
    admin?: boolean;
    superAdmin: boolean;
    ableToImpersonate: boolean;
    hasNewMessages: boolean;
    lastAlertViewDate: Date;
    callingCode?: string;
    phone?: string;
    fullPhone?: string;
    email?: string;
    image?: string;
    logo?: string;
    social?: Social;
    address?: Address;
    access?: Access;
    clientId?: string;
    blockLogon?: boolean;
    snoozeSystemAlerts?: boolean;
    lastLogin?: Date;
    vendorId?: string;
    billing?: string;
    userType?: string;
    clientName?: string;
    vendorName?: string;
    companyName?: string;
    iname: string;
    companyTitle: string;
    team: string;

}

