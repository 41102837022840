@if (_rule.ruleType == 'scheduled' || _rule.ruleType == null) {
  <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">
    <span class="condition-text font-weight-light">When  </span>
    <!-- scopeType -->
    <span class="condition-field" dropdown #ddScope="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing" [insideClick]="true">
      <span dropdownToggle aria-controls="dropdown-scope">{{selectedScope}}</span>
      <ul id="dropdown-scope" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
        @for (scope of scopeTypes; track scope) {
          @if (scope.type === 'Project') {
            <li>
              <a class="dropdown-item cursor-pointer" (click)="applyScope('Project', scope)">{{scope.label}}</a>
            </li>
          }
          @if (scope.type === 'Vendor') {
            @for (vendor of scope.vendors; track vendor) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyScope('Vendor', scope, vendor);">{{vendor.label}}</a>
              </li>
            }
          }
        }
      </ul>
    </span>
    <span class="condition-text">&nbsp;</span>
    <!-- Metrics -->
    <span class="condition-field" dropdown #ddMetric="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing">
      <span dropdownToggle aria-controls="dropdown-metric">{{selectedMetric}}</span>
      <ul id="dropdown-metric" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
        @for (metric of metricTypes; track metric) {
          <li>
            <a class="dropdown-item cursor-pointer" (click)="applyMetric(metric)">{{metric}}</a>
          </li>
        }
      </ul>
    </span>
    <span class="condition-text font-weight-light">  are  </span>
    <!-- Operators -->
    <span class="condition-field" dropdown #ddOperator="bs-dropdown" placement="top right" container="body" [isDisabled]="!_rule.editing">
      <span dropdownToggle aria-controls="dropdown-operator">{{selectedOperator}}</span>
      <ul id="dropdown-operator" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
        @for (operator of operators; track operator) {
          <li>
            <a class="dropdown-item cursor-pointer" (click)="applyOperator(operator)">{{operator.label}}</a>
          </li>
        }
      </ul>
    </span>
    <span class="condition-text">&nbsp;</span>
    <!-- Metric Number -->
    <span class="condition-field" [class.editable]="_rule.editing">
      @if (!_rule.editing) {
        <span>{{selectedMetricNumber.label}}@if (selectedMetricNumber.value > -1) {
          {{selectedMetricNumber.suffix}}
        }</span>
      }
      @if (_rule.editing) {
        <div class="d-inline">
          <input type="text" mask="99999" [patterns]="integerPattern" [(ngModel)]="selectedMetricNumber.label" (blur)="applyMetricNumber()" (keydown)="onKeydown($event, 'number');" (focus)="$event.target.select()" class="form-control form-control-nav form-control-sm text-center d-inline">
          {{selectedMetricNumber.suffix}}
        </div>
      }
    </span>
    <span class="condition-text font-weight-light">&nbsp;by  </span>
    <!-- Date -->
    <span class="condition-field" [class.editable]="_rule.editing">
      @if (!_rule.editing) {
        <span>{{selectedScheduledDate.label}}</span>
      }
      @if (_rule.editing) {
        <div class="d-inline">
          <input type="text"  autocomplete="off"
            (bsValueChange)="selectedScheduledDate.value = $event"
            (onHidden)="applyScheduledDate()"
            (keydown)="onKeydown($event, 'date');"
            bsDatepicker
            readonly
            [bsValue]="selectedScheduledDate.value"
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date',  selectFromOtherMonth: true }"
            class="form-control form-control-sm d-inline bg-white date text-center">
          </div>
        }
      </span>
      <span class="condition-text font-weight-light">&nbsp;at  </span>
      <!-- Time -->
      <span class="condition-field timepicker" [class.editable]="_rule.editing">
        @if (!_rule.editing) {
          <span>{{selectedScheduledTime.label}}</span>
        }
        @if (_rule.editing) {
          <div class="d-inline-flex position-relative" style="top: 5px">
            <timepicker [(ngModel)]="selectedScheduledTime.value" (ngModelChange)="applyScheduledDate()" [showMeridian]="true" [showSpinners]="false" [minuteStep]="1"></timepicker>
          </div>
        }
      </span>
      @if (_rule.editing) {
        <div class="position-relative d-flex align-items-start">
          <span class="condition-text font-weight-light mt-2 mr-1 position-absolute">, alert  </span>
          <!-- Alert Recipient -->
          <ng-template *ngTemplateOutlet="tplAlertRecipient"></ng-template>
          <span class="condition-text font-weight-light position-relative mt-2 mr-1">  by  </span>
          <!-- Alert Method -->
          <span class="condition-field position-relative mt-2" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="false">
            <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
            <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
              @for (alert of alertMethods; track alert) {
                <li>
                  <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
                </li>
              }
            </ul>
          </span>
        </div>
      }
      @if (!_rule.editing) {
        <span class="condition-text font-weight-light mr-1">, alert  </span>
        <!-- Alert Recipient -->
        <ng-template *ngTemplateOutlet="tplAlertRecipient"></ng-template>
        <span class="condition-text font-weight-light mr-1">  by  </span>
        <!-- Alert Method -->
        <span class="condition-field" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="true">
          <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
          <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (alert of alertMethods; track alert) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
              </li>
            }
          </ul>
        </span>
      }
    </span>
  }

  @if (_rule.ruleType == 'live') {
    <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">
      <span class="condition-text font-weight-light">When  </span>
      <!-- scopeType -->
      <span class="condition-field" dropdown #ddScope="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing" [insideClick]="true">
        <span dropdownToggle aria-controls="dropdown-scope">{{selectedScope}}</span>
        <ul id="dropdown-scope" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
          @for (scope of scopeTypes; track scope) {
            @if (scope.type === 'Project') {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyScope('Project', scope)">{{scope.label}}</a>
              </li>
            }
            @if (scope.type === 'Vendor') {
              @for (vendor of scope.vendors; track vendor) {
                <li>
                  <a class="dropdown-item cursor-pointer" (click)="applyScope('Vendor', scope, vendor);">{{vendor.label}}</a>
                </li>
              }
            }
          }
        </ul>
      </span>
      <span class="condition-text">&nbsp;</span>
      <span class="condition-text font-weight-light">Completes  </span>
      <span class="condition-text font-weight-light">  are  </span>
      <span class="condition-text font-weight-light">  Equal to  </span>
      <span class="condition-text">&nbsp;</span>
      <!-- Metric Number -->
      <span class="condition-field" [class.editable]="_rule.editing">
        @if (!_rule.editing) {
          <span>{{selectedMetricNumber.label}}@if (selectedMetricNumber.value > -1) {
            {{selectedMetricNumber.suffix}}
          }</span>
        }
        @if (_rule.editing) {
          <div class="d-inline">
            <input type="text" mask="99999" [patterns]="integerPattern" [(ngModel)]="selectedMetricNumber.label" (blur)="applyMetricNumber()" (keydown)="onKeydown($event, 'number');" (focus)="$event.target.select()" class="form-control form-control-nav form-control-sm d-inline text-center">
            {{selectedMetricNumber.suffix}}
          </div>
        }
      </span>
      @if (_rule.editing) {
        <div class="position-relative d-flex align-items-start">
          <span class="condition-text font-weight-light mt-2 mr-1 position-absolute">, alert  </span>
          <!-- Alert Recipient -->
          <ng-template *ngTemplateOutlet="tplAlertRecipient"></ng-template>
          <span class="condition-text font-weight-light position-relative mt-2 mr-1">  by  </span>
          <!-- Alert Method -->
          <span class="condition-field position-relative mt-2" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="false">
            <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
            <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
              @for (alert of alertMethods; track alert) {
                <li>
                  <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
                </li>
              }
            </ul>
          </span>
        </div>
      }
      @if (!_rule.editing) {
        <span class="condition-text font-weight-light mr-1">, alert  </span>
        <!-- Alert Recipient -->
        <ng-template *ngTemplateOutlet="tplAlertRecipient"></ng-template>
        <span class="condition-text font-weight-light mr-1">  by  </span>
        <!-- Alert Method -->
        <span class="condition-field" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="true">
          <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
          <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (alert of alertMethods; track alert) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
              </li>
            }
          </ul>
        </span>
      }
    </span>
  }

  @if (_rule.ruleType == 'task') {
    <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">
      <span class="condition-text font-weight-light">If Task </span>
      <!-- chosen task type list -->
      <span class="condition-field scrollbar overflow-y" style="max-height: 250px;" dropdown #ddScope="bs-dropdown" container="body" placement="top" [isDisabled]="!_rule.editing" [insideClick]="true">
        <span dropdownToggle aria-controls="dropdown-scope">{{selectedTask}}</span>
        <ul id="dropdown-schedule-item" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
          <div class="scrollbar overflow-y" style="max-height: 250px;">
            @for (task of listTasks; track task) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applySelectedTask(task)">{{task.stage}} | {{task.name}}</a>
              </li>
            }
          </div>
        </ul>
      </span>
      <span class="condition-text">&nbsp;</span>
      <span class="condition-text font-weight-light">  is  </span>
      <span class="condition-text font-weight-light">  not  </span>
      <span class="condition-text font-weight-light">  complete  </span>
      <span class="condition-text font-weight-light">  by  </span>
      <span class="condition-text">&nbsp;</span>
      <!-- Date -->
      <span class="condition-field" [class.editable]="_rule.editing">
        @if (!_rule.editing) {
          <span>{{selectedScheduledDate.label}}</span>
        }
        @if (_rule.editing) {
          <div class="d-inline">
            <input type="text"  autocomplete="off"
              (bsValueChange)="selectedScheduledDate.value = $event"
              (onHidden)="applyScheduledDate()"
              (keydown)="onKeydown($event, 'date');"
              bsDatepicker
              readonly
              [bsValue]="selectedScheduledDate.value"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date',  selectFromOtherMonth: true }"
              class="form-control  form-control-nav form-control-sm d-inline bg-white date text-center">
            </div>
          }
        </span>
        <span class="condition-text font-weight-light">&nbsp;at  </span>
        <!-- Time -->
        <span class="condition-field timepicker" [class.editable]="_rule.editing">
          @if (!_rule.editing) {
            <span>{{selectedScheduledTime.label}}</span>
          }
          @if (_rule.editing) {
            <div class="d-inline-flex position-relative" style="top: 5px">
              <timepicker [(ngModel)]="selectedScheduledTime.value" (ngModelChange)="applyScheduledDate()" [showMeridian]="true" [showSpinners]="false" [minuteStep]="1"></timepicker>
            </div>
          }
        </span>
        <span class="condition-text font-weight-light mr-1">, alert  </span>
        <!-- Alert Recipient -->
        <span class="condition-field" dropdown #ddAlertRecipient="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing">
          <span dropdownToggle aria-controls="dropdown-alertRecipient">{{ selectedTaskAlertRecipient }}</span>
          <ul id="dropdown-alertRecipient" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (recipient of taskAlertRecipients; track recipient) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyTaskAlertRecipient(recipient)">{{ recipient }}</a>
              </li>
            }
          </ul>
        </span>
        <span class="condition-text font-weight-light">  by  </span>
        <!-- Alert Method -->
        <span class="condition-field" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing">
          <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
          <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (alert of alertMethods; track alert) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
              </li>
            }
          </ul>
        </span>
      </span>
    }


    <!-- Templates for Alert Recipient Dropdown -->
    <ng-template #tplAlertRecipient>

      @if (!_rule.editing) {
        <span class="condition-field d-inline-block" style="max-width: 260px;" >
          {{ _rule.ruleActions[0].alertRecipientType.replaceAll(',', ', ') }}
        </span>
      }

      <!-- Adding or Editing -->
      @if (_rule.editing) {
        <div class="alertRecipient condition-field d-inline-block mt-2 mr-1" dropdown #ddAlertRecipient="bs-dropdown" container="body" placement="top right" style="margin-left: 45px;"
          [isDisabled]="!_rule.editing" [class.open]="ddAlertRecipient.isOpen" [insideClick]="true">
          <div dropdownToggle class="cursor-pointer w-100 position-relative" [ngClass]="totalChecked < 2 ? 'd-flex align-items-center' : ''">
            @if (totalChecked == 0) {
              <span class="pr-4 nav-font12 my-1 d-flex-inline selectedAlertRecipient text-truncate" [ngClass]="selectedAlertRecipient === 'Select individual(s)' ? 'grey3' : 'grey5'">
                {{ selectedAlertRecipient }}
              </span>
              <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddAlertRecipient.isOpen"></i>
            }
            @if (totalChecked > 0) {
              @for (recipient of alertRecipients; track recipient; let i = $index) {
                @if (recipient?.checked) {
                  <span class="pr-3 nav-font12 my-1 d-block text-truncate selectedAlertRecipient"
                    [tooltip]="recipient?.type === 'email' ? recipient.label : ''"
                    placement="bottom"
                    [ngClass]="selectedAlertRecipient === 'Select individual(s)' ? 'grey3' : 'grey5'">
                    <div class="or-checkbox pr-3 align-items-center mr-2 grey6 d-inline" style="width: unset;">
                      <input [name]="'chkselectedAlertRecipient'+i" [id]="'chkselectedAlertRecipient'+i" type="checkbox" [(ngModel)]="recipient.checked" (change)="applyAlertRecipient()">
                      <label [for]="'chkselectedAlertRecipient'+i"></label>
                    </div>
                    {{ recipient?.label }}
                  </span>
                }
              }
              <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.position-absolute]="totalChecked > 1" [style]="totalChecked > 1 ? 'top:8px; right: 0;' : ''" [class.fa-rotate-180]="ddAlertRecipient.isOpen"></i>
            }
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-recipient nav-font12 py-0" [style.bottom.px]="dropdownBottom" role="menu">
            @for (recipient of alertRecipients; track recipient; let i = $index) {
              @if (!recipient?.checked) {
                <li role="menuitem" class="mx-1 cursor-pointer">
                  <a class="dropdown-item px-1 pt-2 border-bottom cursor-default d-flex">
                    <div class="or-checkbox pr-3 align-items-center mr-2 grey6">
                      <input [name]="'chkAlertRecipient'+i" [id]="'chkAlertRecipient'+i" type="checkbox" [(ngModel)]="recipient.checked" (change)="applyAlertRecipient()">
                      <label [for]="'chkAlertRecipient'+i"></label>
                    </div>
                    @if (recipient?.type !== 'email') {
                      {{ recipient?.label }}
                    }
                    @if (recipient?.type === 'email') {
                      <textarea autosize [minRows]="1" readonly class="nav-font12 border-0 bg-transparent grey6" style="resize: none;">{{ recipient?.label }}</textarea>
                    }
                  </a>
                </li>
              }
            }
            @if (!hideEmailBox && addingEmail) {
              <li role="menuitem" class="mx-1">
                <a class="dropdown-item recipientEmail px-1 border-bottom">
                  <div class="row">
                    <div class="col px-0 d-flex align-items-center justify-content-center">
                      <i class="fak fa-trash nav-error cursor-pointer pr-2" (click)="deleteRecipientEmail()"></i>
                      <textarea [autosize]="false" rows="2" (keypress)="onKeydown($event, 'email')" [(ngModel)]="newRecipient" class="form-control form-control-sm form-control-nav nav-font12" placeholder="Type e-mail and press enter"></textarea>
                    </div>
                  </div>
                </a>
              </li>
            }
            <li role="menuitem" class="mx-1">
              <a class="dropdown-item px-1 border-bottom" [ngClass]="addingEmail || hideEmailBox ? 'cursor-default' : 'cursor-pointer'">
                <div class="col d-flex align-items-center px-0" [ngClass]="addingEmail || hideEmailBox ? 'grey2' : 'nav-accent-blue'" (click)="!addingEmail ? addRecipientEmail() : null">
                  <i class="far fa-plus pr-2"></i>
                  <span class="font-weight-bold">Add e-mail</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      }

    </ng-template>
