import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService, AuthService, SettingsService, UserService } from 'core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  instanceId = '';
  tab = 'internals';
  users: any[];
  usersInternals: any[];
  usersVendors: any[];
  usersClients: any[];
  deleteUser: any;
  sendUser: any;
  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  @ViewChild('modalResetOrInvite')
  modalRefResetOrInvite: TemplateRef<any>;

  openModalRef: any;
  filterColumns = ['name'];
  filterText: string;
  role: string;
  instances: any[];
  layout = [
    { label: 'Profile', id: 'image', type: 'img' },
    { label: 'Name', id: 'name', sortable: true },
    { label: 'Email', id: 'email', type: 'email', sortable: true },
    { label: 'Phone', id: 'fullPhone' },
    { label: 'Company', id: 'companyName', filterable: true },
    { label: 'Actions', type: 'actions-inline' }
  ];
  actions = [
    { label: 'Send Invite Email', type: 'invite', icon: 'fa-solid fa-paper-plane-top' },
    { label: 'Email Support Access', type: 'support', icon: 'fa-regular fa-handshake' },
    { label: 'Edit User', type: 'edit', icon: 'pen-square' },
    { label: 'Delete User', type: 'delete', icon: 'trash-alt' }
  ];
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  constructor(
    private modalService: BsModalService,
    private settingsService: SettingsService,
    public auth: AuthService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.getUsers();
    this.activatedRoute.queryParams.subscribe(params => {
      this.role = params['role'];

    });
    this.settings = this.settingsService.getUsersViewSettings(this.role);
    const user = this.auth.getUser()
    if (!user.superAdmin) {
      this.actions = this.actions.filter(action => action.type !== 'support');
    }


  }

  ngAfterViewInit() {
    if (this.role !== null) {
      if (this.role === 'internal') {
        this.staticTabs.tabs[0].active = true;
      } else if (this.role === 'vendor') {
        this.staticTabs.tabs[1].active = true;
      } else if (this.role === 'client') {
        this.staticTabs.tabs[2].active = true;

      }
    }
  }

  onSelect($event, role) {
    // this.settings =  { sortBy: null, sortDir: 'ASC', appliedFilters: [] };
    // this.saveUserListSetting(this.settings);
    this.role = role;

    if (this.role === 'vendor' || this.role === 'client') {
      this.actions = this.actions.filter(action => action.type !== 'support');
    } else {
      this.actions = [];
      this.actions = [
        { label: 'Send Invite Email', type: 'invite', icon: 'fa-solid fa-paper-plane-top' },
        { label: 'Email Support Access', type: 'support', icon: 'fa-regular fa-handshake' },
        { label: 'Edit User', type: 'edit', icon: 'pen-square' },
        { label: 'Delete User', type: 'delete', icon: 'trash-alt' }
      ];
    }
    this.getUsers();
    this.settings = this.settingsService.getUsersViewSettings(this.role);

  }
  handleAction(action, role) {
    this.role = role;
    if (action.type === 'edit') {
      this.userService.saveEditUser(action.row);
      this.router.navigate(['/user/edit'], { queryParams: { role: role } });
    }
    else if (action.type === 'delete') {
      this.deleteUser = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
    else if (action.type === 'support') {
      this.userService.sendSupportEmail(action.row).subscribe(data => {
        this.alertify.success('Email send sucessfully to ' + action.row.firstName + ' ' + action.row.lastName);
      }, error => {
        this.alertify.error('Unable to send emailr');
      });
    }
    else if (action.type === 'invite') {
      this.sendUser = action.row;
      this.openModalRef = this.modalService.show(this.modalRefResetOrInvite);

    }
  }
  inviteUser() {
    this.userService.InviteUser(this.sendUser.id).subscribe(data => {
      this.alertify.success('Email send sucessfully to ' + this.sendUser.firstName + ' ' + this.sendUser.lastName);
    }
      , error => {
        this.alertify.error('Unable to send email');
      }
    );
    this.openModalRef.hide();
  }
  sendPasswordReset() {
    var sender = "admin";
    var email = this.sendUser.email;
    this.auth.forgot({ email, sender }).subscribe(result => {
      this.alertify.success("Reset Password email successfully sent");
    }, error => {
      this.alertify.error("Reset Password email failed to send");
    });
    this.openModalRef.hide();
  }

  confirmDelete() {
    this.userService.DeleteUser(this.deleteUser.id).subscribe(data => {
    }, error => {
      this.alertify.error('Unable to delete user');
    },
      () => {
        this.alertify.success(this.deleteUser.firstName + ' ' + this.deleteUser.lastName + ' deleted successfully');
        this.openModalRef.hide();
        this.getUsers();
      });
  }

  settings: any = {};
  saveUserListSetting(settings) {
    this.settingsService.setUsersViewSetting(settings, this.role);
  }


  getUsersViewSettings() {
    this.settings = this.settingsService.getUsersViewSettings(this.role);
  }

  getUsers() {
    this.userService.GetInstanceUsers(this.instanceId).subscribe(data => {
      data.forEach(e => {
        if (e.clientName != null && e.clientName !== '') {
          e.userType = 'Client';
          e.companyName = e.clientName
        }
        else if (e.vendorName != null && e.vendorName !== '') {
          e.userType = 'Vendor';
          e.companyName = e.vendorName
        }
        else e.userType = 'Internal';
      });

      this.users = data;
      this.usersVendors = data.filter(x => x.userType == 'Vendor');
      this.usersInternals = data.filter(x => x.userType == 'Internal');
      this.usersClients = data.filter(x => x.userType == 'Client');

      if (this.role !== undefined) {
        if (this.role === 'vendor') {

          if (this.settings.sortBy !== null) {
            this.usersVendors = this.sortOrder(this.usersVendors, this.settings.sortBy.id, this.settings.sortDir)
          }
        } else if (this.role === 'internal') {

          if (this.settings.sortBy !== null) {
            this.usersInternals = this.sortOrder(this.usersInternals, this.settings.sortBy.id, this.settings.sortDir)
          }
        } else if (this.role === 'client') {

          if (this.settings.sortBy !== null) {
            this.usersClients = this.sortOrder(this.usersClients, this.settings.sortBy.id, this.settings.sortDir)
          }
        }
      }
    }, error => {
      this.alertify.error('Unable to get users');
    });
  }


  sortOrder(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'name') {
        A = a.name;
        B = b.name;
      }

      if (type == 'email') {
        A = a.email;
        B = b.email;
      }
      if (type == 'company') {
        A = a.company;
        B = b.company;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

}
