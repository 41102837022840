import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService, AuthService, QuotaQuestion, ProjectService } from 'core';
import {  BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { QuotaService } from 'projects/core/src/services/quota.service';

@Component({
  selector: 'app-quota-questions',
  templateUrl: './quota-questions.component.html',
  styleUrls: ['./quota-questions.component.scss']
})
export class QuotaQuestionsComponent implements OnInit {

  instanceId: string;
  questions: any[] = [];
  locations: any[];
  model: QuotaQuestion;
  questionList: any[] = [];
  deleteQuestion: any;
  questionTypes: string[];
  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  layout = [
    {label: 'Question', id: 'question'},
    {label: 'Category', id: 'category'},
    {label: 'Type', id: 'type'},
    {label: 'Edit', id: 'action', type: 'actions-inline'},
  ];
  actions = [{label: 'Edit', type: 'edit', icon: 'pen-square'}]; //{label: 'Delete', type: 'delete', icon: 'trash-alt'} 

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private alertify: AlertifyService,
    private quotaService: QuotaService,
    private projectService: ProjectService,
    public auth: AuthService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.loadData();
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
        this.router.navigate(['/quota-edit'], {state: {data: dataEdit }});      
    }
    if (action.type === 'delete') {
      this.deleteQuestion = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  loadData() {
    this.questionTypes = [ 'Gender', 'Age', 'Region', 'HouseholdIncome', 'Ethnicity', 'Hispanic'] //'State', 'Division'
    var questionList = [];
    this.questions = [];
    this.quotaService.ListQuestions().subscribe( data => {
      data.forEach(element => {
        for (var n = 0; n < this.questionTypes.length; n++) {

          if (element.type == this.questionTypes[n]) {
            element.order = n;
            questionList.push(element)
            this.questionTypes.splice(n, 1);
          }
        }
      })

      if (this.questionTypes.length > 0) { // if not all questions exist, add them
        
        this.questionTypes.forEach(q => {
          var options: any[] = []
          var defaults: any[] = []
          var questionData = new FormGroup({
            id:  new FormControl(null),
            question: new FormControl(''),
            category: new FormControl('Consumer'),
            type: new FormControl(q),
            description: new FormControl(''),
            inputType: new FormControl(''),
            instanceId: new FormControl(this.instanceId),
            options: new FormArray([]),
            defaults: new FormArray([]),
            urlIdentifier: new FormControl('')
          })

          if (q == 'Region') {
            questionData.controls['inputType'].setValue('UserInput');
            questionData.controls['question'].setValue('Please enter your zip code.');
            questionData.controls['urlIdentifier'].setValue('zip')

            this.projectService.GetLocations(q.toLowerCase()).subscribe(data => {
              this.locations = data.map(obj => ({ value: obj }));
              this.locations.forEach(item => {
                if (item.value) {
                  (questionData.get('defaults') as FormArray).push(
                    new FormGroup({
                      id: new FormControl(null),
                      min: new FormControl(0),
                      max: new FormControl(0),
                      value: new FormControl(item.value),
                      percent: new FormControl(0),
                    })
                  );
                }
              });

              this.addDefaultQuestion(questionData)
             });
          }

          if (q == 'Gender'){
            questionData.controls['inputType'].setValue('RadioButtons');
            questionData.controls['question'].setValue('To which gender identity do you most identify?');
            questionData.controls['urlIdentifier'].setValue('gender')

            defaults = [
              {value: 'Female', max: 0, min: 0, percent:21},
              {value: 'Male', max: 0, min: 0, percent:30},
              {value: 'Not Listed', max: 0, min: 0, percent:20}
            ]
          }

          if (q == 'Hispanic'){
            questionData.controls['inputType'].setValue('RadioButtons');
            questionData.controls['question'].setValue('Are you of Hispanic, Latino, or Spanish origin?');
            questionData.controls['urlIdentifier'].setValue('hispanic')

            defaults = [
              {value: 'Yes, Hispanic', max: 0, min: 0, percent:50},
              {value: 'No, Not-Hispanic', max: 0, min: 0, percent:50},
            ]
          }

          if (q == 'Ethnicity'){
            questionData.controls['inputType'].setValue('RadioButtons');
            questionData.controls['question'].setValue('How do you best describe your ethnicity?');
            questionData.controls['urlIdentifier'].setValue('ethnicity')

            defaults = [
              {value: 'Asian', max: 0, min: 0, percent:14}, 
              {value: 'Black or African American', max: 0, min: 0, percent:14}, 
              {value: 'Native Hawaiian or Other Pacific Islander', max: 0, min: 0, percent:15},
              {value: 'Native American Indian or Alaska Native', max: 0, min: 0, percent:15}, 
              {value: 'White/Caucasian', max: 0, min: 0, percent:14}, 
              {value: 'Unknown', max: 0, min: 0, percent:14}, 
              {value: 'Other/Prefer to Self-Describe', max: 0, min: 0, percent:14}, 
            ]
          }

          if (q == 'Age'){
            questionData.controls['inputType'].setValue('UserInput');
            questionData.controls['question'].setValue('What is your age?');
            questionData.controls['urlIdentifier'].setValue('age');

            defaults = [
              {value: null, min: 18, max: 25, percent:20},
              {value: null, min: 26, max: 35, percent:30},
              {value: null, min: 36, max: 45, percent:20},
              {value: null, min: 46, max: 55, percent:20},
              {value: null, min: 56, max: 99, percent:10},
            ]
          }

          if (q == 'HouseholdIncome'){
            questionData.controls['inputType'].setValue('DropDown');
            questionData.controls['question'].setValue('What is your Annual Household Income?');
            questionData.controls['urlIdentifier'].setValue('householdincome')

            var increment = 5000;
            var minVal = 15000;
            var maxVal = 150000;
            
            options.push({ value: (minVal-1).toString(), label: 'Less than ' + minVal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0}), index:0 })
            for(var n = minVal; n + increment <= maxVal; n+=increment) {
              var number: number = n + increment -1 
              options.push({value: n.toString(), label: n.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0}) + ' - ' + number.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0}), index: options.length}, 
              )
            }
            options.push({value: maxVal.toString(), label:  maxVal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0}) + ' or More', index: options.length })

            defaults = [
              {value: null, min: 0, max: 19999, percent:5},
              {value: null, min: 20000, max: 49999, percent:45},
              {value: null, min: 50000, max: 99999, percent:40},
              {value: null, min: 100000, max: 149999, percent:10}]
          }        
          

          if (q === 'Ethnicity' || q === 'Gender' || q === 'Hispanic') {
            defaults.forEach(item => {
              (questionData.get('options') as FormArray).push(
                new FormGroup({
                  id: new FormControl(item.id),
                  value: new FormControl(item.value),
                  label: new FormControl(item.value),
                  default: new FormControl(item.percent),
                  order: new FormControl(questionData.value.options.length)
                })
              );
            });
          }
          else {
            var labelForOption;

            options.forEach(item => {
              if (q == 'HouseholdIncome') {
                labelForOption = item.label;
              }
              else {
                labelForOption = item.value
              }
              (questionData.get('options') as FormArray).push(
                new FormGroup({
                  id: new FormControl(null),
                  value: new FormControl(item.value),
                  label: new FormControl(labelForOption),
                  default: new FormControl(0),
                  order: new FormControl(questionData.value.options.length)
                })
              );
            });
          }

          defaults.forEach(item => {
            (questionData.get('defaults') as FormArray).push(
              new FormGroup({
                id: new FormControl(null),
                min: new FormControl(item.min),
                max: new FormControl(item.max),
                value: new FormControl(item.value),
                percent: new FormControl(item.percent),
              })
            );
          });


          this.addDefaultQuestion(questionData)
        });   
        this.router.navigate(['/quota-questions']);
      }



      this.sort(questionList);
      this.questions = questionList;
    }, error => {
       this.alertify.error('Unable to fetch Quota Questions');
    });
  }

  sort(array) {

    array.sort((a, b) => {

      let comparison = 0;
      if (a.order > b.order) {
        comparison = 1;
      } else if (a.order < b.order) {
        comparison = -1;
      }
      return comparison;
    });
  }

  addDefaultQuestion (questionData) {
    this.quotaService.AddQuestion(questionData.value).subscribe( data => {
      this.model = questionData.value

    }, error => {
    },
    () => {
      this.router.navigate(['/quota-questions']);
    });
  }

  confirmDelete() {
    this.quotaService.DeleteQuestion(this.deleteQuestion).subscribe( data => {

    }, error => {
       this.alertify.error('Unable to delete Quota Question');
       this.openModalRef.hide();
    },
    () => {
      this.alertify.success('Quota Question deleted successfully');
      this.openModalRef.hide();
      this.loadData();
    });
  }

}
