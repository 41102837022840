<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Project Health Configuration</p>
    <div class="float-right">
      <div class="form-inline form">
        @if (!this.configSelected) {
          <button type="button" (click)="createNewConfig()" class="btn btn-sm btn-outline-light inline"><em class="fa fa-plus-circle"></em> Add Config</button>
        }
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <div class="row">
        <div class="col-lg-12 col-xl-12">

          @if (!this.configSelected) {
            <div class="table-responsive">
              <or-list [items]="this.configs" [layout]="layout" [actions]="actions" [enableExport]="false" (action)="handleAction($event)"></or-list>
            </div>
          }


          @if (this.configSelected) {
            <div class=" mainCard ml-5 mr-5">
              <div class="row pb-1">
                <div class="col-md-4 col-sm-12 form-group">
                  <label for="projectName" class="required">Name </label>
                  <input type="text" [(ngModel)]="this.configName" (ngModelChange)="configNameChange()" maxlength=40 name="configName" id="projectName" class="form-control form-control-sm" [ngClass]="!nameValid && utils.showCssValidField(editForm, 'configName')">
                  <div _ngcontent-ihk-c127="" class="invalid-feedback">Please enter name.</div>
                </div>
                <div class="col-md-5 col-sm-12  mr-1 form-group">
                  <label for="projectDesc">Description </label>
                  <input type="text" [(ngModel)]="this.configDesc" maxlength=100 name="configDesc" id="projectDesc" class="form-control form-control-sm">
                </div>
                <div class="col-md-3 float-right col-sm-12 mt-4 p-0 pt-1 pl-2" style="max-width:160px;">
                  <button class="cancelBtn btn btn-sm btn-outline-secondary mr-2" (click)="cancelEdit()" type="button">Close</button>
                  <button class="saveBtn btn btn-sm or-primary-bg text-white" type="submit" (click)="saveHealthConfig()">Save Config</button>
                </div>
              </div>
              <ng-template *ngTemplateOutlet="projectHealthscoreKey"></ng-template>
              <div class="d-flex mb-3 mt-1 border-bottom ml-1 mr-1">
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab1'" (click)="renderGeneral()">General</a>
                </div>
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab2'" (click)="renderIR()">IR</a>
                </div>
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab3'" (click)="renderTest()">Test Settings</a>
                </div>
              </div>
              @if (tabselector == 'tab1') {
                <ng-container class="tabs">
                  <div class="row GeneralSettingsRow ml-5">
                    <div class="col-md-3 col-sm-12 mt-4">
                      <div class="row mb-3 float-right pr-3 pb-2">
                        <table>
                          <thead><th></th><th style="width: 50%"></th></thead>
                          <tbody>
                            <tr>
                              <td class="text-right pr-3"># Min Starts</td>
                              <td  class="float-right">
                                <div class="input-group">
                                  <input maxlength="7"
                                    (keypress)="numberOnly('percent', starts, $event)"
                                    type="text"
                                    name="minStart"
                                    [(ngModel)]="starts[0].value"
                                    class="form-control form-control-sm text-right"
                                    placeholder="# of starts">
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row pt-3 float-right pr-3 borderTop">
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th style="width:30%" class="tableHeading text-center text-secondary">Penalty</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-right pr-3">Negative Pace:</td>
                                <td>
                                  <div class="input-group ">
                                    <input
                                      maxlength="3"
                                      (keypress)="numberOnly('penalty', pace[0].negPace, $event)"
                                      type="text" name="negPace"
                                      [(ngModel)]="pace[0].negPace"
                                      (ngModelChange)="updateElement('pace', 0, 'negPace', $event)"
                                      class="form-control form-control-sm text-right">
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-5 col-sm-12 mb-5 mt-2 ml-5 pl-5">
                          <div class="row pb-4">
                            <div class="col settingTitle pl-4">Manual QC</div>
                          </div>
                          <div class="card-body pl-0 pr-0 pt-0">
                            <div class="mt-0">
                              <table>
                                <thead>
                                  <tr>
                                    <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">When over by</th>
                                    <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                    <th style="width: 20%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  @for (element of qc; track element; let i = $index) {
                                    <ng-container class="row mb-2">
                                      <tr>
                                        <td class="pb-2">
                                          <div class="input-group input-group-sm">
                                            <input
                                              (keypress)="numberOnly('percent', element.percentage, $event)"
                                              type="text"
                                              maxlength="3"
                                              name="qcPercent"
                                              [(ngModel)]=element.percentage
                                              (ngModelChange)="updateElement('qc', i, 'percentage', $event)"
                                              class="form-control form-control-sm">
                                            <div class="input-group-append">
                                              <span class="input-group-text">%</span>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="pb-2">
                                          <div class="pl-3 input-group input-group-sm">
                                            <input
                                              [(ngModel)]=element.value (ngModelChange)="updateElement('qc', i, 'value', $event)"
                                              maxlength="3"
                                              (keypress)="numberOnly('penalty', element.value, $event)" type="text"
                                              name="qcPenalty"
                                              class="form-control form-control-sm">
                                          </div>
                                        </td>
                                        @if (i != 0) {
                                          <td class="col-1 pl-2 pt-1">
                                            <button class="btn btn-secondary removeBtn" (click)="removeElement('qc', i)"><i class="fa fa-times"></i></button>
                                          </td>
                                        }
                                      </tr>
                                    </ng-container>
                                  }
                                </tbody>
                              </table>
                              <div class="row">
                                <div class="col-10 text-right pr-0 ml-3">
                                  <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('qc')">+ ADD</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row pb-4">
                            <div class="col settingTitle pl-4">CID-Fraud Rate</div>
                          </div>
                          <div class="card-body pl-0 pr-0 pt-0">
                            <div class="mt-0">
                              <table>
                                <thead>
                                  <tr>
                                    <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">When over by</th>
                                    <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                    <th style="width: 20%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  @for (element of fraud; track element; let i = $index) {
                                    <ng-container class="row mb-2">
                                      <tr>
                                        <td class="pb-2">
                                          <div class="input-group input-group-sm">
                                            <input
                                              (keypress)="numberOnly('percent', element.percentage, $event)"
                                              type="text"
                                              maxlength="3"
                                              name="fraudPercent"
                                              [(ngModel)]=element.percentage
                                              (ngModelChange)="updateElement('fraud', i, 'percentage', $event)"
                                              class="form-control form-control-sm">
                                          <div class="input-group-append">
                                            <span class="input-group-text">%</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="pb-2">
                                        <div class="pl-3 input-group input-group-sm">
                                          <input
                                            [(ngModel)]=element.value (ngModelChange)="updateElement('fraud', i, 'value', $event)"
                                            maxlength="3"
                                            (keypress)="numberOnly('penalty', element.value, $event)" type="text"
                                            name="fraudPenalty"
                                            class="form-control form-control-sm">
                                          </div>
                                        </td>
                                        @if (i != 0) {
                                          <td class="col-1 pl-2 pt-1">
                                            <button class="btn btn-secondary removeBtn" (click)="removeElement('fraud', i)"><i class="fa fa-times"></i></button>
                                          </td>
                                        }
                                      </tr>
                                    </ng-container>
                                  }
                                </tbody>
                              </table>
                              <div class="row">
                                <div class="col-10 text-right pr-0 ml-3">
                                  <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('qc')">+ ADD</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    }
                    @if (tabselector == 'tab2') {
                      <or-data-grid [sign]="irSign" [x_label]="irXLabel" [y_label]="irYLabel" [type]="irType" [data]="ir"></or-data-grid>
                    }
                    @if (tabselector == 'tab3') {
                      <div class="row mt-4 mb-3">
                        <div class="col-1"></div>
                        <div class="col-1 pr-0 mr-0" style="min-width:100px; max-width: 100px" >
                          <label>Total Score:</label>
                        </div>
                        <div class="col-1 d-flex" style="min-width:80px; max-width: 80px">
                          <div class="input-group input-group-sm">
                            <input type="number" [(ngModel)]="totalTestScore" readonly class="form-control form-control-sm">
                          </div>
                        </div>
                      </div>
                      <div class="row tests mr-5 ml-5">
                        @for (test of tests; track test; let i = $index) {
                          <div class="mb-4" style="width:240px;">
                            @if (test.testType == 'IR') {
                              <div>
                                <div class="card-header p-0 mt-3">
                                  <p class="h5 text-secondary">{{test.testType}}</p>
                                </div>
                                <hr>
                                  <div class="card-body mb-3 mt-3 p-0">
                                    <div class="row">
                                      <div class="col-3 text-right" >
                                        <label>Expected:</label>
                                      </div>
                                      @if (test.testType == 'IR') {
                                        <div class="col-5">
                                          <div class="input-group input-group-sm">
                                            <input
                                              (keypress)="numberOnly('percent', test.expected, $event)"
                                              type="text"
                                              maxlength="5"
                                              class="form-control form-control-sm form-control-sm"
                                              [(ngModel)]=test.expected (ngModelChange)="setTestValues(i, 'expected', $event)">
                                              <div class="input-group-append">
                                                <span class="input-group-text">{{irSign}}</span>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        </div>
                                        <div class="row mt-1">
                                          <div class="col-3 text-right">
                                            <label>Actual:</label>
                                          </div>
                                          @if (test.testType == 'IR') {
                                            <div class="col-5">
                                              <div class="input-group input-group-sm">
                                                <input
                                                  (keypress)="numberOnly('percent', test.actual, $event)"
                                                  type="text"
                                                  maxlength="5"
                                                  class="form-control form-control-sm form-control-sm"
                                                  [(ngModel)]=test.actual (ngModelChange)="setTestValues(i, 'actual', $event)">
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">{{irSign}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                            
                                            </div>
                                            <div class="row mt-1">
                                              <div class="col-3">
                                                <label>Score:</label>
                                              </div>
                                              <div class="col-1" style="min-width:30%">
                                                <div class="input-group input-group-sm">
                                                  <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      @if (test.testType != 'Starts' && test.testType != 'IR' && test.testType != 'LOI' && test.testType != 'Pace') {
                                        <div>
                                          <div class="card-header p-0 mt-3">
                                            <p class="h5 text-secondary">{{test.testType}}</p>
                                          </div>
                                          <hr>
                                            <div class="card-body mb-3 mt-3 p-0">
                                              <div class="row">
                                                <div class="col-3 text-left pr-1" >
                                                  @if (test.testType == 'QC' || test.testType == 'Fraud') {
                                                    <label>Over by:</label>
                                                  }
                                                </div>
                                                <div class="col-5">
                                                  <div class="input-group input-group-sm">
                                                    <input
                                                      (keypress)="numberOnly('percent', test.rate, $event)"
                                                      type="text"
                                                      maxlength="5"
                                                      class="form-control form-control-sm form-control-sm"
                                                      [(ngModel)]=test.rate
                                                      (ngModelChange)="setTestValues(i, 'rate', $event)">
                                                      @if (test.testType == 'QC' || test.testType == 'Fraud') {
                                                        <div class="input-group-append">
                                                          <span class="input-group-text">%</span>
                                                        </div>
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row mt-1">
                                                  <div class="col-3 text-left">
                                                    <label>Score:</label>
                                                  </div>
                                                  <div class="col-1" style="min-width:30%">
                                                    <div class="input-group input-group-sm">
                                                      <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          @if (test.testType == 'Pace') {
                                            <div>
                                              <div class="card-header p-0 mt-3">
                                                <p class="h5 text-secondary">{{test.testType}}</p>
                                              </div>
                                              <hr>
                                                <div class="card-body mb-3 mt-3 p-0">
                                                  <div class="row mt-1">
                                                    <div class="col-5 pr-0 text-left" >
                                                      <label>Negative Pace:</label>
                                                    </div>
                                                    <div class="col-5">
                                                      <div>
                                                        <input type="checkbox" class="m-1" [(ngModel)]=test.negPace (ngModelChange)="setTestValues(i, 'negPace', $event)">
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row mt-1">
                                                    <div class="col-5 text-left">
                                                      <label>Score:</label>
                                                    </div>
                                                    <div class="col-1" style="min-width:30%">
                                                      <div class="input-group input-group-sm">
                                                        <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                            @if (test.testType == 'Starts') {
                                              <div>
                                                <div class="card-header p-0 mt-3">
                                                  <p class="h5 text-secondary">{{test.testType}}</p>
                                                </div>
                                                <hr>
                                                  <div class="card-body mb-3 mt-3 p-0">
                                                    <div class="row">
                                                      <div class="col-3 text-left" >
                                                        <label>Starts:</label>
                                                      </div>
                                                      <div class="col-4">
                                                        <div class="input-group input-group-sm">
                                                          <input type="number" class="form-control form-control-sm form-control-sm" [(ngModel)]=test.value (ngModelChange)="setTestValues(i, 'value', $event)">
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


<ng-template #projectHealthscoreKey>
  <div class="d-flex align-items-center row mx-2 nav-font14 grey5 pb-3">
    <div class="mr-3">Project Health Score:</div>
    <div class="mr-2 good-score"><span class="far fa-square-check nav-success mr-2"></span>0-3</div>
    <div class="mx-2 medium-score"> <span class="far fa-triangle-exclamation nav-medium-risk mr-2"></span>4-8</div>
    <div class="mx-2 bad-score"><span class="far fa-triangle-exclamation nav-error mr-2"></span>9+</div>
  </div>
</ng-template>