<form [formGroup]="editForm" (ngSubmit)="submitForm()" novalidate>
  <div class="card m-3">
    <div class="card-header text-white or-primary-bg">
      <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> Instance Details</p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="row mb-6">
            <div class="col-3">
              <label for="name" class="required">Name</label>
              <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(editForm, 'name')">
            </div>
            <div class="col-6">
              <label for="description">Description</label>
              <input type="text" class="form-control" id="description" formControlName="description">
            </div>           
          </div>
        </div>
      </div>

      <hr class="mb-4" />

      <div class="row">
        <div class="col mb-3">
          <div class="row mb-3">
            <div class="col-3">
              <label for="productType" class="required">Product Type</label>
              <select class="form-control" formControlName="productType" id="productType">
                <option value="RID">RID</option>
                <option value="CleanID">CleanID</option>
                <option value="CleanID and ResponseID">CleanID and ResponseID</option>
                <option value="Quality">Quality</option>
                <option value="Enterprise">Enterprise</option>
              </select> 
            </div>
            <div class="col-3">
              <label for="licenseType">License Type</label>
              <select class="form-control" formControlName="licenseType" id="licenseType">
                <option value="Trial">Trial</option>
                <option value="Contract">Contract</option>
                <option value="Expired">Expired</option>                
              </select> 
            </div>
            <div class="col-3">
              <span tooltip="Client Secret used for server to server calls to NavigatOR">
                <i class="fas fa-info-circle opacity-20 or-secondary"></i>
              </span>
              <label for="clientSecret" class="ml-2">Client Secret</label>
              <div class="row">
                <div class="col">
                  <div class="input-group">
                    @if (model != null && model.clientSecret != null) {
                      <div class="input-group">
                        <input type="text" disabled class="form-control" [value]="model.clientSecret" id="clientSecret" placeholder="">
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <hr class="mb-4" />
      <div class="row">
        <div class="col-md-3 mb-3">
          <div><label for="projectAccess">CleanID Enable</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'cleanID'" formControlName="cleanIDEnable" class="light">
            </or-toggle-button>
          </button>
        </div>
        @if (editForm.value.cleanIDEnable) {
          <div class="col-md-3 mb-3">
            <span tooltip="Private key used for server to server calls to CleanID">
              <i class="fas fa-info-circle opacity-20 or-secondary"></i>
            </span>
            <label for="cleanIDPrivateKey" class="ml-2">CleanID Private Key</label>
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="cleanIDPrivateKey" id="cleanIDPrivateKey" placeholder="">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Generate New Key" (click)="createNewKey('cleanIDPrivateKey')"><i class="fa fa-solid fa-redo"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <span tooltip="Client ID used for server to server calls">
              <i class="fas fa-info-circle opacity-20 or-secondary"></i>
            </span>
            <label for="cleanIDClientID" class="ml-2">Instance ID</label>
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <div class="input-group">
                    <input type="text" disabled class="form-control" formControlName="id" id="cleanIDClienID" placeholder="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md  mb-3">
            <div><label for="projectAccess">CleanID Trial</label></div>
            <button type="button" class="btn btn-outline-light btn-sm border-0">
              <or-toggle-button [id]="'cleanIDLicenseTrial'" formControlName="cleanIDLicenseTrial" class="light"></or-toggle-button>
            </button>
          </div>
          <div class="col-md  mb-3 mr-2">
            <div><label for="projectAccess">Implementation</label></div>
            <select class="col-12 form-control form-control-sm" formControlName="cleanIDImplementation" name="cleanIDImplementation" [(ngModel)]="this.model.cleanIDImplementation">
              <option selected [value]="'NavigatOR'" >NavigatOR</option>
              <option selected [value]="'API'">API</option>
            </select>
          </div>
        }
      </div>
      <hr class="mb-4" />

      <div class="row">
        <div class="col-md-3 mb-3">
          <div><label for="teamAccess">ResponseID Enable</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'responseID'" formControlName="responseIDEnable" class="light">
            </or-toggle-button>
          </button>
        </div>
        @if (editForm.value.responseIDEnable) {
          <div class="col-md-3 mb-3">
            <label for="responseIDPrivateKey">ResponseID Private Key</label>
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="responseIDPrivateKey" id="responseIDPrivateKey" placeholder="">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Generate New Key" (click)="createNewKey('responseIDPrivateKey')"><em class="fa fa-solid fa-redo"></em></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div class="col-md-3 mb-3">

      </div>

      <hr class="mb-4" />

      <hr class="mb-4" />
      <div class="row">
        <div class="col-md-2 mb-3">
          <div><label for="projectAccess">Project Enable</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'projectID'" formControlName="projectEnable" class="light">
            </or-toggle-button>
          </button>
        </div>
        <div class="col-md-2 mb-3">
          <div><label for="projectAccess">Client Enabled</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'client'" formControlName="clientEnable" class="light">
            </or-toggle-button>
          </button>
        </div>
        <div class="col-md-2 mb-3">
          <div><label for="projectAccess">Open Clients View</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'client'" formControlName="clientView" class="light">
            </or-toggle-button>
          </button>
        </div>
        <div class="col-md-2 mb-3">
          <div><label for="teamAccess">Vendor Enabled</label></div>
          <button type="button" class="btn btn-outline-light btn-sm border-0">
            <or-toggle-button [id]="'partner'" formControlName="partnerEnable" class="light">
            </or-toggle-button>
          </button>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <div class="btn btn-light mr-2" (click)="cancelClick()"   type="button">Cancel</div>
      <button class="btn or-primary-bg text-white" type="submit">Save</button>
    </div>
  </div>
</form>
<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm New Key</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to update the Key?</p>
    <p class="h6">If a client is using the current key then the server to server calls may fail until they update to the new key.</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmNewKey()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>