<div class="container col-3">
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" class="form-signin  mt-5 text-center">

    <h1 class="h3 mb-3 font-weight-normal">Change Password</h1>
    <label for="currentPassword" class="sr-only">Current Password</label>
    <input type="password" class="form-control"
      [ngClass]="{'is-invalid': changePasswordForm.get('currentPassword').errors && changePasswordForm.get('currentPassword').touched}"
      id="currentPassword" formControlName="currentPassword" placeholder="Current Password">
      @if (changePasswordForm.get('currentPassword').hasError('required') && changePasswordForm.get('currentPassword').touched) {
        <div class="invalid-feedback"
          >
        Current password is required</div>
      }

      <label for="password" class="sr-only">New Password</label>
      <input type="password" class="form-control"
        [ngClass]="{'is-invalid': changePasswordForm.get('password').errors && changePasswordForm.get('password').touched}"
        id="password" formControlName="password" placeholder="New Password">
        @if (changePasswordForm.get('password').hasError('required') && changePasswordForm.get('password').touched) {
          <div class="invalid-feedback"
            >
          New password is required</div>
        }
        @if (changePasswordForm.get('password').hasError('passwordComplexity') && changePasswordForm.get('password').touched) {
          <div class="invalid-feedback"
            >
            Password must be at least 6 characters and contain an uppercase and lowercase character, a digit, and a
          non-alphanumeric character</div>
        }

        <label for="confirmPassword" class="sr-only">Confirm Password</label>
        <input type="password" class="form-control is-invalid"
          [ngClass]="{'is-invalid': changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').touched ||changePasswordForm.get('confirmPassword').touched &&changePasswordForm.hasError('mismatch')}"
          id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password">
          @if (changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').touched ) {
            <div class="invalid-feedback"
              >
            Confirm password is required!</div>
          }
          @if (changePasswordForm.hasError('mismatch') && changePasswordForm.get('confirmPassword').touched ) {
            <div class="invalid-feedback"
              >
            Passwords must match!</div>
          }


          <button class="btn btn-lg or-primary-bg text-white btn-block" [disabled]="!changePasswordForm.valid"
          type="submit">Sign In</button>


          <p class="mt-5 mb-3 text-muted">©2025</p>
        </form>

      </div>