import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { SupportService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import { ChatBotRequest } from '../../models/chatbot-request';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'or-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  constructor(public supportService: SupportService, private ngZone: NgZone, private sanitizer: DomSanitizer) {  }

  @ViewChild('chatBox') chatBox: any;
  @ViewChild('textArea') textArea: ElementRef<HTMLTextAreaElement>;
  messages: { text: string, type: string, doneProcessing: boolean}[] = [];
  userMessage: string = '';
  showChat = false;
  isOverflowing = false;
  waitingForResponse = false;
  sessionId = null;
  nextQuestions = [];
  sources = [];
  extrasFromResponse = '';
  stream = false;
  rowCount = 1;
  chatBoxHeight = 138;
  minRows: number = 1; // Set your minimum row count
  isProcessingChunk = false;
  chunkQueue: string[] = [];

  ngOnInit(): void {
  }

  sendMessage(text = null) {
    if (text == null)
    {
      text = this.userMessage;
    }    
    text = text.trim();
    if (text === '' || this.waitingForResponse) return;

    this.waitingForResponse = true;
    this.nextQuestions = [];
    this.extrasFromResponse = '';
    this.messages.push({text: text, type: 'user', doneProcessing: true});
    var index = this.messages.length;

    this.checkForYOverflow();
    try {
      var request : ChatBotRequest = {
        requestText: text,
        sessionId: this.sessionId
      }
      this.supportService.streamMessage(request, (chunk) => {
        // handle on complete


        if (chunk.length <= 0) return;

        this.ngZone.run(() => {
          this.waitingForResponse = false;        
        });
        this.chunkQueue.push(chunk);  // Push the chunk into the queue
        if (!this.isProcessingChunk) {
          this.processNextChunk(index);  // Start processing the next chunk if not already processing
        }
      }, () => {
      });
    } catch (error) {
      console.error('Error fetching response:', error);
      this.waitingForResponse = false;
      var error = 'Error communicating with the server.';
      this.messages.push({text: error, type: 'bot', doneProcessing: true});
    }
    console.log(this.messages)
    this.userMessage = '';
    this.getTextareaRowCount();
    this.scrollToBottom();
  }

  reformatText(index) {
    var message = this.messages[index];
    var text = message.text;

      text = text.replace(/<h1>/g, '<h3>').replace(/<\/h1>/g, '</h3>');
      text = text.replace(/<h2>/g, '<h4>').replace(/<\/h2>/g, '</h4>');
      text = text.replace(/<code class="language-html">/g, '<code>') 
      text = text.replace(/```html/g, '<pre><code>')
      
      // replace with <pre><code></pre></code> when ``` and ``` at the end 
      text = text.replace(/```javascript/g, '<pre><code>')
      text = text.replace(/```/g, '</code></pre>');
      text = text.replace(/<code>([\s\S]*?)<\/code>/g, (_, codeContent) =>
          `<code>${codeContent
              .replace(/<br>/g, "\n")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
          }</code>`
        );  
      this.messages[index].text = text;

    this.checkForYOverflow();
    this.scrollToBottom();
  }

  extractExtras() {
    // if <userPrompts></userPrompts> tag is found, get the userprompts each inside a <userPrompt></userPrompt> tag
    if (this.extrasFromResponse.includes('<userPrompts>') && this.extrasFromResponse.includes('</userPrompts>')) {
      var userPrompts = this.extrasFromResponse.match(/<userPrompt>([\s\S]*?)<\/userPrompt>/g);
      if (userPrompts) {
        this.nextQuestions = userPrompts.map(prompt => prompt.replace(/<userPrompt>/g, '').replace(/<\/userPrompt>/g, ''));
        // remove <userPrompts> and </userPrompts> tags and content from text
        // text = text.replace(/<userPrompts>([\s\S]*?)<\/userPrompts>/g, '');
        console.log("next questions: ", this.nextQuestions)
      }
    }

    if (this.extrasFromResponse.includes('<links>') && this.extrasFromResponse.includes('</links>')) {
      var links = this.extrasFromResponse.match(/<link>([\s\S]*?)<\/link>/g);
      if (links) {
        this.sources = links.map(link => link.replace(/<link>/g, '').replace(/<\/link>/g, ''));
        // remove <links> and </links> tags and content from text
        // text = text.replace(/<links>([\s\S]*?)<\/links>/g, '');
        console.log("sources: ", this.sources)
      }
    }
    this.checkForYOverflow();
    this.scrollToBottom();
  }

  // Function to process each chunk sequentially
  processNextChunk(index) {
    if (this.chunkQueue.length > 0) {
      this.isProcessingChunk = true;  // Set flag that we're processing a chunk
      const chunk = this.chunkQueue.shift(); // Get the next chunk in the queue

        if (!this.messages[index]) { // if no message exists at the index, create one
          this.messages[index] = { text: '', type: 'bot', doneProcessing: false };
        }
        // Process the chunk one character at a time with typing effect
        this.processChunkSequentially(chunk, index);
    
    } else {
      this.isProcessingChunk = false;  // Reset flag when there are no more chunks
      this.reformatText(index);
      this.extractExtras();
    }
  }

  // Function to handle processing a chunk sequentially (character by character)
  processChunkSequentially(chunk: string, index: number) {
    let charIndex = 0;

    if (!this.messages[index].doneProcessing) { 
      const addChar = () => {
        if (charIndex < chunk.length) {
          this.ngZone.run(() => {
            this.messages[index].text += chunk[charIndex]; // Add one character at a time

            // check if the message last chars end on [!END] and set doneProcessing to true
            if (this.messages[index].text.endsWith('[!END]')) {
              this.messages[index].doneProcessing = true;
              this.messages[index].text = this.messages[index].text.slice(0, -6);
            }
            this.scrollToBottom();
          });
          charIndex++; // Move to the next character
          if (!this.messages[index].doneProcessing) setTimeout(addChar, 10); // Adjust delay for typing effect
          else {
            this.extrasFromResponse += chunk.slice(charIndex);
            charIndex = chunk.length;
            addChar();
          }
        } else {
          this.ngZone.run(() => {
            this.processNextChunk(index);  // Continue processing next chunk in queue
          });
        }
      };
  
      addChar();
    }
    else {
      this.extrasFromResponse += chunk;
      charIndex = chunk.length;
      this.ngZone.run(() => {
        this.processNextChunk(index);  // Continue processing next chunk in queue
      });
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
    }, 100);
  }

  selectSuggestion(text) {
    this.sendMessage(text);
  }

  toggleChat() {
    this.showChat = !this.showChat;
    // this.nextQuestions = [
    //   "How does CleanID work?",
    //   "What are CleanID's main features?",
    //   "How can CleanID benefit market research projects project projects?",
    //   "Where can I learn more about CleanID?"
    // ]

    this.nextQuestions = [];
    this.sources = [];
    this.userMessage = '';
    if (this.showChat) {
      this.sessionId = uuidv4();
    }
    else {
      this.sessionId = null;
      this.messages = [];
      this.isOverflowing = false;
      this.waitingForResponse = false;
    }

    // setTimeout(() => {
    //   this.addMessage("<p>To use the CleanID API, follow these steps:</p><ol><li>Include the CleanID JavaScript file in your HTML page:</li><pre><code><script type=\"text/javascript\" data-nc id=\"cleanid_script_id\" src=\"https://idsuite.navigatorsurveys.com/cleanid-v3.current.min.js\" data-key=\"your_API_key_here\"></script></code></pre><li>Create event handler callbacks for success and failure:</li><pre><code><script type=\"text/javascript\">function successCallback(jsonObject) null function errorCallback(jsonObject) null</script></code></pre><li>Create the input parameters to pass to the API.</li><li>Call the CleanID API:</li><pre><code><script type=\"text/javascript\">IDSuite.cleanid({RequestId: \"Resp123\", PanelistId: null, EventId: \"P12345\", Event: \"CleanID Test Project\", ChannelId: \"1\", Channel: \"Partner A\", GeoRestrictionEnabled: true, Countries: [\"us\",\"gb\"], Status: \"Start\", DuplicateStatus: [\"Complete\", \"QC\"], DuplicateEvents: [{EventId:\"Wave1\", DuplicateStatus:[\"Complete\"]}, {EventId:\"Wave2\", DuplicateStatus:[\"Complete\"]}], onSuccess: successCallback, onError: errorCallback, FullDataSet: true});</script></code></pre><li>Process the response in the callback methods.</li></ol>", "bot");
    // }, 100);
  }

  handleEmptyInput(event: KeyboardEvent) {
    if (event.key === 'Enter') event.preventDefault();
    var text = this.userMessage.trim();
    if (text == '') {
      if (event.key === ' ') event.preventDefault();
      this.userMessage = '';
    }
  }

  checkForYOverflow() {
    // delay by 10ms to allow for DOM update
    setTimeout(() => {
      if (this.chatBox.nativeElement.scrollHeight > this.chatBox.nativeElement.clientHeight) {
        this.isOverflowing = true;
      } else {
        this.isOverflowing = false;
      }
    }, 10);
  }

  getTextareaRowCount() {
    // timeout to catch up with DOM update
    setTimeout(() => {
      const textarea = this.textArea.nativeElement;
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10) || 20;

      // Reset height to auto to allow shrinkage
      textarea.style.overflowY = "hidden";
      textarea.style.height = 'auto';
      var scrollHeight = textarea.scrollHeight;
      // Set new height based on scrollHeight
      textarea.style.height = `${scrollHeight}px`;

      // Calculate row count
      var rowCount = (Math.round(scrollHeight / (lineHeight*10))) * 10;
      this.rowCount = rowCount;
  
      if (scrollHeight <= 78) {
        this.chatBoxHeight = 138;
      }
      else {
        this.chatBoxHeight = 145 + (scrollHeight-87);
      }
    }, 10);
    
  }
}
