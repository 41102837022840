export interface JumpDataFileReport {
    report_file_id: string;
    respondent_count: number;
    start_time: string;
    end_time: string;
    last_access_date: string;
    status: string;
    finished_Respondent_Count: number;
    layoutFile: boolean;
    language: string;
    filter_Name: string;
    friendly_Name_Override: string;
}

export interface JumpDataFileReportList {
    reports: JumpDataFileReport[];
    reportCurrentlyGenerating: boolean;
}

export interface JumpDataFileInfo {
    filters: JumpDataFileFilter[];
    start_date: string;
    types: JumpDataFileType[];
    languages: JumpDataFileLanguage[];
    purgeDate: string;
}

export interface JumpDataFileGenerationResponse {
    reportFileId: string;
    reportCurrentlyGenerating: boolean;
}

export interface JumpDataFileFilter {
    value: string;
    text: string;
}

export interface JumpDataFileType {
    value: string;
    text: string;
}

export interface JumpDataFileLanguage {
    value: string;
    text: string;
    language_id: number;
}

export interface JumpDataFileGenerationRequestBody {
    format: string;
    reportid: string;
    filter: string;
    language: string;
    starttime: string;
    endtime: string;
}

export type JumpDataFileDownloadFileType = "data" | "json" | "layout";

export enum JumpDataFileStatus {
    Initialized = "Initialized",
    Failed = "Failed",
	Completed = "Completed",
	Generating = "Generating",
	Canceled = "Canceled"
}